import { useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import {
  QrcodeIcon,
  ChevronRightIcon,
  XIcon,
  ChevronLeftIcon,
  ReceiptTaxIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  CogIcon,
} from "@heroicons/react/outline";
import QrCodeModal from "components/QrCodeModal/QrCodeModal";
import { translate, toast } from "utils";
import Modal from "components/Modal/Modal";
import useLocalStorage from "hooks/useLocalStorage";
import { useHistory } from "react-router-dom";

const OfferCardMenu = ({
  offerId,
  type,
  offerSlug,
  publicId,
  enabled,
  eventType,
  updateOfferStatus,
  onDeleteClick,
}: {
  offerId?;
  type?;
  offerSlug?;
  publicId?;
  enabled?;
  eventType?;
  updateOfferStatus?;
  onDeleteClick?;
}) => {
  const [showQR, setShowQR] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const isMobile = window.innerWidth < 768;
  const [, setFilteredOffer] = useLocalStorage("filteredOffer", "");
  const history = useHistory();
  const editLink = `/my/settings/offers/${type}/${offerSlug}`;

  const copyLink = `${publicId}/${type}/${offerSlug}`;

  const handleDataForFiltePromotions = () => {
    setFilteredOffer(offerId);
    history.push(`/my/settings/promotions`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowQR(false);
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClipboard = ({
    text,
    toastText,
    isLink = true,
  }: {
    text: string;
    toastText?: string;
    isLink?: boolean;
  }) => {
    try {
      window.navigator.clipboard.writeText(
        isLink ? `${window.location.origin}/${text}` : text
      );
      toast(
        toastText ? translate(toastText) : translate("toastProfileCopied"),
        {
          type: "success",
        }
      );
      setMenuOpen(false);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const toggleMenu = () => {
    if (showQR) {
      setShowQR(false);
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  const handleShare = async (param, text) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: text,
          text,
          url: `${window.location.origin}/${param}`,
        });
      } else {
        handleClipboard({ text: param, toastText: "toastUrlCopied" });
      }
    } catch (error) {
      console.log("Error sharing", error);
    }
  };

  const menuItems = [
    {
      id: 1,
      label: translate("buttonEdit"),
      icon: <PencilIcon className="w-6 h-6 text-slate-500 mr-1" />,
      onClick: () => history.push(editLink),
    },
    {
      id: 2,
      label: translate("share"),
      icon: (
        <span className="icon-[icon-park-outline--share-two] w-6 h-6 text-slate-500 mr-1" />
      ),
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: copyLink,
              toastText: "toastUrlCopied",
            })
          : handleShare(copyLink, "Waylight Offer link"),
    },
    {
      id: 3,
      label: translate("qRCode"),
      icon: <QrcodeIcon className="h-6 w-6 text-slate-500 mr-1" />,
      onClick: () => {
        setShowQR(true);
        setMenuOpen(false);
      },
      hasArrow: true,
    },
    {
      id: 4,
      label: translate("manage"),
      icon: <CogIcon className="h-6 w-6 text-slate-500 mr-1" />,
      onClick: () => history.push(`/my/${type}/${offerSlug}`),
    },
    {
      id: 5,
      label: translate("promotion"),
      icon: <ReceiptTaxIcon className="h-6 w-6 text-slate-500 mr-1" />,
      onClick: handleDataForFiltePromotions,
    },
    {
      id: 6,
      label: translate("turnOf"),
      icon: (
        <span className="icon-[el--off] h-5 w-5 text-slate-500 ml-0.5 mr-2" />
      ),
      onClick: () => {
        toggleMenu();

        updateOfferStatus(offerSlug, false, eventType);
      },
    },
    {
      id: 7,
      label: translate("buttonDelete"),
      icon: <TrashIcon className="h-6 w-6 text-slate-500 mr-1" />,
      onClick: () => onDeleteClick(offerId),
    },
  ];
  const filteredMenuItems = menuItems.filter((item) => {
    if (type === "tip" || type === "product" || type === "appointment") {
      return (
        item.label !== translate("manage") &&
        (type !== "tip" || item.label !== translate("promotion"))
      );
    }
    return true;
  });

  return (
    <>
      {isMobile ? (
        <>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="w-auto flex absolute -bottom-6 bg-transparent md:bg-transparent rounded-full text-white md:text-slate-900 items-center justify-center md:justify-start gap-1 px-5 lg:px-2 py-3 font-semibold"
          >
            <DotsVerticalIcon className="text-slate-400 h-6 w-6" />
          </button>
          <Modal
            widthType="xxs"
            isOpen={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            <>
              <div className="pt-3 pb-2" />

              <ul className="space-y-6">
                {filteredMenuItems.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between items-center cursor-pointer"
                    onClick={item.onClick}
                  >
                    <div className="flex items-center space-x-2">
                      {item.icon}
                      <span className="text-sm text-slate-900 font-medium">
                        {item.label}
                      </span>
                    </div>

                    <ChevronRightIcon className="h-6 w-6 text-slate-500" />
                  </li>
                ))}
              </ul>
            </>
          </Modal>
          <Modal
            widthType="xxs"
            isOpen={showQR}
            onClose={() => setShowQR(false)}
          >
            <h3 className="text-base flex justify-center pb-4 font-semibold text-gray-900">
              {translate("myProfileQRCode")}
            </h3>
            <ChevronLeftIcon
              className="absolute left-3 top-6 w-6 h-6 cursor-pointer opacity-50"
              onClick={() => {
                setMenuOpen(!menuOpen);
                setShowQR(false);
              }}
            />
            <hr className="mt-2 mb-4 border-gray-200" />
            <QrCodeModal link={publicId} type="share" />
          </Modal>
        </>
      ) : (
        <div className="relative flex overflow-visible" ref={menuRef}>
          <button
            onClick={toggleMenu}
            className="w-auto flex absolute -bottom-6 bg-slate-900 md:bg-transparent rounded-full text-white md:text-slate-900 items-center justify-center md:justify-start gap-1 px-5 lg:px-2 py-3 font-semibold"
          >
            <DotsVerticalIcon className="text-slate-400 h-6 w-6" />
          </button>

          <Transition
            show={menuOpen && !showQR}
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-100 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="absolute -bottom-[5px] -right-2 mt-2 w-[280px] bg-white shadow-lg rounded-lg p-4 z-50 border">
              <ul className="mt-5">
                {filteredMenuItems.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between items-center py-3 px-4 cursor-pointer hover:bg-gray-100 hover:rounded-lg transition-all duration-200"
                    onClick={item.onClick}
                  >
                    <div className="flex items-center space-x-2">
                      {item.icon}
                      <span className="text-sm text-slate-900 font-medium">
                        {item.label}
                      </span>
                    </div>
                    {/* <span className="icon-[codicon--arrow-small-right] h-6 w-6 text-slate-500" /> */}
                    <ChevronRightIcon className="h-6 w-6 text-slate-500" />
                  </li>
                ))}
              </ul>

              <XIcon
                className="absolute right-3 top-3 w-6 h-6 cursor-pointer opacity-50"
                onClick={toggleMenu}
              />
            </div>
          </Transition>

          {showQR && (
            <div className="absolute -bottom-[5px] -right-2 mt-2 w-[300px] bg-white shadow-lg rounded-lg p-4 z-50 border">
              <div className="flex justify-center mb-4 mt-6">
                <h3 className="text-base font-semibold text-gray-900">
                  {translate("myProfileQRCode")}
                </h3>
                <ChevronLeftIcon
                  className="absolute left-3 top-4 w-6 h-6 cursor-pointer opacity-50"
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                    setShowQR(false);
                  }}
                />
                <XIcon
                  className="absolute right-3 top-3 w-6 h-6 cursor-pointer opacity-50"
                  onClick={() => setShowQR(false)}
                />
              </div>
              <hr className="mt-2 mb-4 border-gray-200" />
              <QrCodeModal link={copyLink} type="share" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OfferCardMenu;
