import { useState } from "react";
import cx from "classnames";
import { convertRating, getFullName, formatDate } from "utils";
import EmptyState from "components/EmptyState/EmptyState";
import ReviewItem from "components/ReviewItem/ReviewItem";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

const ExpertReviewList = ({
  reviews,
  isReviewLoading,
  isMyProfile,
}: {
  reviews: any[];
  isReviewLoading: boolean;
  isMyProfile?: boolean;
}) => {
  const [filterType, setFilterType] = useState<"newest" | "oldest">("newest");
  const isNewestType = filterType === "newest";
  const { t } = useTranslation();

  const sortedByNewest = [...(reviews ?? [])]?.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const sortedByOldest = [...(reviews ?? [])]?.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return (
    <>
      <div className="mb-5 md:mb-3 relative flex justify-center font-medium">
        <div>
          <button
            className={cx("mr-5", { "text-gray-400": !isNewestType })}
            onClick={() => {
              setFilterType("newest");
            }}
          >
            {t("newest")}
          </button>
          <button
            className={cx("", { "text-gray-400": isNewestType })}
            onClick={() => {
              setFilterType("oldest");
            }}
          >
            {t("oldest")}
          </button>
        </div>
      </div>
      <div className="">
        {isReviewLoading ? (
          <div className="mt-10  mb-9 text-center">
            <Loader fullScreen={false} />
          </div>
        ) : reviews?.length ? (
          <div className={cx("flex gap-y-3 flex-col")}>
            {isNewestType
              ? sortedByNewest.map(
                  ({
                    authorAvatar,
                    authorFirstName,
                    authorLastName,
                    authorLocalpart,
                    createdAt,
                    rating,
                    text,
                    authorId,
                    authorSocialNetworks,
                    authorBio,
                    authorCity,
                  }) => (
                    <ReviewItem
                      raiting={convertRating(rating)}
                      text={text}
                      clientName={getFullName(
                        authorFirstName,
                        authorLastName,
                        authorLocalpart
                      )}
                      reviewDate={formatDate(createdAt)}
                      clientImg={authorAvatar}
                      key={createdAt}
                      type="list"
                      authorId={authorId}
                      authorSocialNetworks={authorSocialNetworks}
                      authorBio={authorBio}
                      isMyProfile={isMyProfile}
                      authorCity={authorCity}
                    />
                  )
                )
              : sortedByOldest.map(
                  ({
                    authorAvatar,
                    authorFirstName,
                    authorLastName,
                    authorLocalpart,
                    createdAt,
                    rating,
                    text,
                    authorId,
                    authorSocialNetworks,
                    authorBio,
                    authorCity,
                  }) => (
                    <ReviewItem
                      raiting={convertRating(rating)}
                      text={text}
                      clientName={getFullName(
                        authorFirstName,
                        authorLastName,
                        authorLocalpart
                      )}
                      reviewDate={formatDate(createdAt)}
                      clientImg={authorAvatar}
                      key={createdAt}
                      type="list"
                      authorId={authorId}
                      authorSocialNetworks={authorSocialNetworks}
                      authorBio={authorBio}
                      isMyProfile={isMyProfile}
                      authorCity={authorCity}
                    />
                  )
                )}
          </div>
        ) : (
          <EmptyState header="emptyReview" className="col-span-2" />
        )}
      </div>
    </>
  );
};

export default ExpertReviewList;
