import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRouter } from "next/router";
import { PlusSmIcon } from "@heroicons/react/outline";
import { ExpertOfferListItem } from "types/user";
import {
  handleClipboard,
  getUpdatedPrice,
  isDateInPast,
  findCountryByCode,
} from "utils";
import EmptyState from "components/EmptyState/EmptyState";
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import { getHostName } from "helpers";
import { api } from "utils/api";
import { useQuery } from "@tanstack/react-query";
import { Me } from "types/user";
import { utcToTimeZone } from "utils/timeZoneUtils";
import useLocalStorage from "hooks/useLocalStorage";
import { utcToZonedTime } from "date-fns-tz";
import CommunityExpertCard from "components/ExpertOfferList/CommunityExpertCard";
import EventExpertCard from "components/ExpertOfferList/EventExpertCard";
import ProductExpertCard from "components/ExpertOfferList/ProductExpertCard";
import SessionExpertCard from "components/ExpertOfferList/SessionExpertCard";
import TipExpertCard from "components/ExpertOfferList/TipExpertCard";
import { useTranslation } from "react-i18next";

const ExpertOfferList = ({
  offers,
  publicId,
  type = "default",
  isBusinessType = false,
  loading,
  isMyProfile = false,
  children,
  offerType = "appointment",
  timeZonePublic,
  isSoldEnabled,
  updateOfferStatus,
  onDeleteClick,
  isPreview,
}: {
  offers: ExpertOfferListItem[];
  publicId: string;
  type?: "default" | "public";
  isBusinessType?: boolean;
  loading?: boolean;
  children?;
  isMyProfile?: boolean;
  offerType?:
    | "appointment"
    | "product"
    | "tip"
    | "community"
    | "session"
    | "event";
  eventType?: string;
  eventDate?: string;
  eventDateEnd?: string;
  participants?: number;
  sold?: number;
  timeZonePublic?: string;
  isSoldEnabled?: boolean;
  updateOfferStatus?;
  onDeleteClick?;
  isPreview?: boolean;
}) => {
  const router = useRouter();
  const history = useHistory();
  const isPublicType = type === "public";
  const { data } = useQuery<Me>(["me"], () => api.get("/users/v1/users/me"), {
    enabled: isPublicType === false,
  });
  const [timeZone, setTimezone] = useState(data?.timeZone);
  const isMyProfileis = data?.expert?.publicId === publicId;
  const isTutorial = offerType === "product";
  const isTip = offerType === "tip";
  const isOffer = offerType === "appointment";
  const isCommunity = offerType === "community";
  const isEvent = offerType === "event";
  const [timeFormat, setTimeFormat] = useLocalStorage("timeFormat", "24h");
  const { t } = useTranslation();
  const { data: isTelegramConnected } = useQuery(
    ["telegram"],
    () => api.get("/users/v1/telegram/subscribe"),
    {
      enabled: isPublicType === false,
    }
  );
  useEffect(() => {
    setTimezone(data?.timeZone);
  }, []);

  const handleBookOffer = (id: string) => {
    if (isBusinessType || isPreview) {
      return window.open(
        `/${publicId}/${offerType}/${id}`,
        "_blank",
        "noopener,noreferrer"
      );
    }

    if (isPublicType) {
      router.push(`/${publicId}/${offerType}/${id}`);
      return;
    }

    history.push(`/${publicId}/${offerType}/${id}`);
  };

  const handleCopy = (id: string) => {
    handleClipboard({
      text: `${publicId}/${offerType}/${id}`,
      toastText: "toastOfferUrlCopied",
    });
  };

  if (loading) {
    return (
      <div className="mb-9 px-3 py-4 shadow rounded-lg bg-white text-center">
        <Loader fullScreen={false} />
      </div>
    );
  }

  const getOrderingEvents = (events, timeZone) => {
    const pastEvents = events.filter((event) => {
      const isPast = isDateInPast(event.eventDateEnd, timeZone);
      return isPast;
    });

    const futureEvents = events.filter(
      (event) => !isDateInPast(event.eventDateEnd, timeZone)
    );
    pastEvents.sort(
      (a, b) =>
        new Date(b.eventDateEnd).getTime() - new Date(a.eventDateEnd).getTime()
    );
    futureEvents.sort((a, b) => a.priority - b.priority);

    return [...futureEvents, ...pastEvents];
  };

  const filteredOffers = isEvent
    ? getOrderingEvents(offers, isPublicType ? timeZonePublic : data?.timeZone)
    : [...offers].sort((a, b) => a.priority - b.priority);

  const onCreateRedirect = () =>
    history.push(
      isOffer
        ? "/my/settings/offers/createOffer#appointments"
        : offerType === "community"
        ? `/my/settings/offers/createOffer#communities`
        : `/my/settings/offers/createOffer#${offerType}s`
    );

  function compareDates(offerDateStr, timeZone) {
    if (offerDateStr && timeZone) {
      const today = utcToZonedTime(new Date(), timeZone);
      const utcDate = utcToTimeZone(`${offerDateStr}.000Z`, timeZone);
      return today > utcDate;
    }
    return;
  }
  function isCurrentTimeInRange(eventDateStr, eventDateEndStr, timeZone) {
    if (!eventDateStr || !eventDateEndStr || !timeZone) {
      return false;
    } else {
      const today = utcToZonedTime(new Date(), timeZone);
      const eventStartDate = utcToTimeZone(`${eventDateStr}.000Z`, timeZone);
      const eventEndDate = utcToTimeZone(`${eventDateEndStr}.000Z`, timeZone);
      return today >= eventStartDate && today <= eventEndDate;
    }
  }

  const telegramLink = `${process.env.NEXT_PUBLIC_TELEGRAM_BOT}?start=${btoa(
    data?.id
  )}`;

  function seatsLeft(levels: { seats?: string; sold?: number }[]): string {
    if (!levels) {
      return `0 ${t("seatsLeft")}`;
    }
    let totalSeats = 0;
    let totalSold = 0;
    levels.forEach((level) => {
      totalSeats += level.seats ? parseInt(level.seats) : 0;
      totalSold += level.sold ? level.sold : 0;
    });
    let seatsLeft = totalSeats - totalSold;
    return `${seatsLeft < 0 ? 0 : seatsLeft} ${t("seatsLeft")} `;
  }

  function isSoldOut(
    levels: { seats?: string; sold?: string | number }[]
  ): boolean {
    if (!levels) {
      return false;
    }

    const totalSeats = levels.reduce((sum, level) => {
      const seats = level.seats ? parseInt(level.seats) : 0;
      const sold =
        typeof level.sold === "number"
          ? level.sold
          : parseInt(level.sold || "0");
      return sum + (seats - sold);
    }, 0);

    return totalSeats <= 0;
  }

  return (
    <>
      {children}
      {filteredOffers?.length ? (
        <div className="px-5 md:px-0">
          {filteredOffers
            .filter((offer: any) =>
              isBusinessType || isTutorial || isCommunity || isTip || isEvent
                ? !offer.isDisabled
                : !offer.isDisabled && offer.sharing === "public"
            )
            .map(
              ({
                price,
                offerSlug,
                name,
                priceType,
                duration,
                description,
                sharing,
                mediaUrl,
                attachemntUrl,
                cover,
                sections,
                expiration,
                sold,
                daysUntilExpiration,
                bonus,
                subscribed,
                joined,
                inviteLink,
                title,
                eventType,
                eventDate,
                eventDateEnd,
                participants,
                instoreids,
                levels,
                trial,
                currency,
                timezone,
                city,
                adaptivePlaylistCover,
                communityTitle,
                cardSize,
                communication,
                offerId,
                isEnabled,
              }) => {
                const isFreeOffer = !isEvent
                  ? price === "0.00"
                  : levels[0].price === 0.0;

                const editLink = `/my/settings/offers/${offerType}/${offerSlug}`;

                const manageLink =
                  offerType === "appointment"
                    ? `/my/dashboard#appointments`
                    : `/my/${offerType}/${offerSlug}`;

                const offerPrice = getUpdatedPrice(price);

                const countryCurrency = findCountryByCode(currency);
                const IconComponent: any = countryCurrency?.icon;

                const handleCardClick = (e) => {
                  if (
                    e.target.getAttribute("data-is-video") ||
                    e.target.getAttribute("data-is-link") ||
                    e.target.getAttribute("data-is-qr") ||
                    e.target.tagName === "VIDEO"
                  ) {
                    return;
                  }
                  while (e.target) {
                    if (e.target.getAttribute("data-is-video")) {
                      return;
                    }
                    e.target = e.target.parentElement;
                  }
                  e.preventDefault();
                  e.stopPropagation();
                  handleBookOffer(offerSlug);
                };

                const isBoughtTutorial =
                  !!sections?.length && !isBusinessType && !isMyProfile;

                const isBoughtEvent = isEvent && sections && !isMyProfileis;

                const isExpired =
                  isBoughtTutorial &&
                  expiration > 0 &&
                  daysUntilExpiration <= 0;

                const section = sections?.[0];

                const hostName = getHostName(section?.link);
                const isVimeoOrYoutube =
                  hostName === "youtube" || hostName === "vimeo";

                const tipLink = `${publicId}/${offerType}/${offerSlug}`;

                const props = {
                  publicId,
                  isBusinessType,
                  loading,
                  isMyProfile,
                  children,
                  offerType,
                  timeZonePublic,
                  isSoldEnabled,
                  offerSlug,
                  offers,
                  handleCardClick,
                  name,
                  IconComponent,
                  subscribed,
                  offerPrice,
                  levels,
                  daysUntilExpiration,
                  expiration,
                  sold,
                  joined,
                  sections,
                  cover,
                  countryCurrency,
                  trial,
                  telegramLink,
                  handleCopy,
                  sharing,
                  editLink,
                  manageLink,
                  isMyProfileis,
                  isPublicType,
                  isTelegramConnected,
                  compareDates,
                  eventDate,
                  timeZone,
                  isCurrentTimeInRange,
                  eventDateEnd,
                  eventType,
                  timezone,
                  city,
                  isBoughtEvent,
                  isFreeOffer,
                  timeFormat,
                  instoreids,
                  isSoldOut,
                  price,
                  seatsLeft,
                  mediaUrl,
                  attachemntUrl,
                  isBoughtTutorial,
                  isExpired,
                  isVimeoOrYoutube,
                  section,
                  priceType,
                  duration,
                  bonus,
                  tipLink,
                  inviteLink,
                  title,
                  adaptivePlaylistCover,
                  communityTitle,
                  cardSize,
                  communication,
                  updateOfferStatus,
                  offerId,
                  isEnabled,
                  onDeleteClick,
                  isPreview,
                };

                return (
                  <>
                    {isCommunity && <CommunityExpertCard {...props} />}
                    {isEvent && <EventExpertCard {...props} />}
                    {isTutorial && <ProductExpertCard {...props} />}
                    {isOffer && <SessionExpertCard {...props} />}
                    {isTip && <TipExpertCard {...props} />}
                  </>
                );
              }
            )}
        </div>
      ) : (
        <EmptyState header={t("noOffers")}>
          {isBusinessType ? (
            <Button
              onClick={onCreateRedirect}
              icon={<PlusSmIcon className="text-white w-5 h-5" />}
              className="mt-6"
            >
              {isOffer ? t("newSessionOffer") : null}
              {isTutorial ? t("newTutorialOffer") : null}
              {isTip ? t("newTipOffer") : null}
              {isCommunity ? t("newCommunityOffer") : null}
              {isEvent ? t("newEventOffer") : null}
            </Button>
          ) : null}
        </EmptyState>
      )}
    </>
  );
};

export default ExpertOfferList;
