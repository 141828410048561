import { useEffect, useState } from "react";
import { PencilIcon, ViewGridIcon, ViewListIcon } from "@heroicons/react/solid";

import cx from "classnames";
import { BlobItem } from "types/experts";
import { TABLET_VIEW } from "constants/dimensions";
import useWindowDimensions from "hooks/useWindowDimensions";
import { divideArray, getFileIconWithColor } from "utils";
import EmptyState from "components/EmptyState/EmptyState";
import ExpertPortfolioContentItem from "./components/ExpertPortfolioContentItem";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";

const ExpertPortfolioContent = ({
  portfolioMediaList,
  portfolioImgList,
  portfolioOtherList,
  setSelectedItem,
  setShowPortfolio,
  listType,
  isPresentationPlaying,
  setIsPresentationPlaying,
  handleOpenEditPortfolio,
}: {
  portfolioMediaList: BlobItem[];
  portfolioImgList: any[];
  portfolioOtherList: BlobItem[];
  setSelectedItem: (value: number | ((prevState: number) => number)) => void;
  setShowPortfolio: (
    value: boolean | ((prevState: boolean) => boolean)
  ) => void;
  listType: "media" | "other";
  isPresentationPlaying: boolean;
  setIsPresentationPlaying: (
    value: boolean | ((prevState: boolean) => boolean)
  ) => void;
  handleOpenEditPortfolio?: () => void;
}) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  const [portfolioType, setPortfolioType] = useState<"media" | "other">(
    "media"
  );
  const { width } = useWindowDimensions();
  const [portfolioViewType, setPortfolioViewType] = useState<"list" | "grid">(
    "grid"
  );

  const onMediaClick = () => {
    setPortfolioType("media");
    setPortfolioViewType("grid");
  };
  const onOtherClick = () => {
    setPortfolioType("other");
    setPortfolioViewType("grid");
  };

  useEffect(() => {
    if (listType === "other") {
      onOtherClick();
    }
  }, [listType]);

  const isMediaType = portfolioType === "media";
  const isListType = portfolioViewType === "list";
  const { t } = useTranslation();
  const sortedPortfolioMediaList = portfolioMediaList?.sort(
    (a, b) => Number(a.flags?.order) - Number(b.flags?.order)
  );

  const { firstArray, secondArray } = divideArray(sortedPortfolioMediaList);
  const isTablet = width <= TABLET_VIEW;

  const defaultProps = {
    portfolioImgList,
    setSelectedItem,
    setShowPortfolio,
    isPresentationPlaying,
    setIsPresentationPlaying,
    currentIndex,
    setCurrentIndex,
    playVideo,
    setPlayVideo,
    isListType,
    isTablet,
  };

  return (
    <>
      <div className="relative flex justify-center font-medium">
        <div className="flex justify-center pb-5 md:pb-3">
          <button
            className={cx("mr-5", {
              "text-gray-400 hover:text-gray-600": !isMediaType,
            })}
            onClick={onMediaClick}
          >
            {t("media")}
          </button>
          <button
            className={cx("", {
              "text-gray-400 hover:text-gray-600": isMediaType,
            })}
            onClick={() => {
              setPortfolioType("other");
              setPortfolioViewType("grid");
            }}
          >
            {t("other")}
          </button>
        </div>
        <div className="hidden md:flex gap-x-2 absolute top-0 right-0">
          <ViewGridIcon
            className={cx("w-5 h-5 hover:text-gray-600 cursor-pointer", {
              "text-gray-400": isListType,
            })}
            onClick={() => {
              setPortfolioViewType("grid");
            }}
          />
          <ViewListIcon
            className={cx("w-5 h-5 hover:text-gray-600 cursor-pointer", {
              "text-gray-400": !isListType,
            })}
            onClick={() => {
              setPortfolioViewType("list");
            }}
          />
        </div>
      </div>
      <div className="mx-5 md:mx-0 grid grid-cols-2 gap-3">
        {isMediaType ? (
          portfolioMediaList?.length ? (
            isTablet || isListType ? (
              sortedPortfolioMediaList.map((file: any, index: number) => {
                return (
                  typeof window !== "undefined" && (
                    <ExpertPortfolioContentItem
                      {...defaultProps}
                      file={file}
                      index={index}
                      key={file.link}
                    />
                  )
                );
              })
            ) : (
              <>
                <div className="col-span-1">
                  {firstArray.map((file: any, index: number) => {
                    return (
                      <ExpertPortfolioContentItem
                        {...defaultProps}
                        file={file}
                        index={index}
                        key={file.link}
                      />
                    );
                  })}
                </div>
                <div className="col-span-1">
                  {secondArray.map((file: any, index: number) => {
                    return (
                      <ExpertPortfolioContentItem
                        {...defaultProps}
                        file={file}
                        index={index + firstArray.length}
                        key={file.link}
                      />
                    );
                  })}
                </div>
              </>
            )
          ) : (
            <EmptyState header="emptyPortfolio" className="col-span-2">
              {handleOpenEditPortfolio ? (
                <Button
                  onClick={handleOpenEditPortfolio}
                  icon={<PencilIcon className="w-5" />}
                  className="flex items-center justify-center px-8 py-2 font-semibold max-w-full rounded-lg shadow mt-6"
                >
                  <span className="truncate">{t("editPortfolio")}</span>
                </Button>
              ) : null}
            </EmptyState>
          )
        ) : portfolioOtherList?.length ? (
          [...portfolioOtherList]
            .sort((a, b) => Number(a.flags?.order) - Number(b.flags?.order))
            .map(({ fileName, link, id }) => {
              const fileIcon = getFileIconWithColor(fileName);

              return (
                <div
                  className={cx("h-72 overflow-hidden cursor-pointer", {
                    "col-span-2 h-72 md-h-auto md:col-span-1": !isListType,
                    "col-span-2 h-72": isListType,
                  })}
                  key={id}
                >
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <div
                      className="relative flex flex-col items-center justify-center gap-2 shadow-lg w-full h-full p-3 text-center"
                      style={{ backgroundColor: fileIcon.color }}
                    >
                      <div className="w-[72px] h-[92px]  drop-shadow-2xl opacity-80">
                        {fileIcon.icon}
                      </div>
                      <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-3">
                        <p
                          className="text-left text-white text-base font-semibold truncate"
                          title={fileName}
                        >
                          {fileName}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })
        ) : (
          <EmptyState header="emptyPortfolio" className="col-span-2">
            {handleOpenEditPortfolio ? (
              <Button
                onClick={handleOpenEditPortfolio}
                icon={<PencilIcon className="w-5" />}
                className="flex items-center justify-center px-8 py-2 font-semibold max-w-full rounded-lg shadow mt-6"
              >
                <span className="truncate">{t("editPortfolio")}</span>
              </Button>
            ) : null}
          </EmptyState>
        )}
      </div>
    </>
  );
};

export default ExpertPortfolioContent;
