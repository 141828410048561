import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import cx from "classnames";

const PortfolioButton = ({
  onClick,
  disabled,
  currentIndex,
  lastIndex,
  type,
}: {
  onClick: any;
  disabled: boolean;
  currentIndex: number;
  lastIndex?: number;
  type: "left" | "right";
}) => {
  const isLeftBtn = type === "left";
  const isHidden =
    (!isLeftBtn && currentIndex === lastIndex) ||
    (isLeftBtn && currentIndex <= 1);

  return (
    <button
      className={cx(
        "absolute top-1/2 transform -translate-y-1/2 z-10 transition duration-200 text-white hover:text-slate-50 hover:scale-110 focus:outline-none w-8 sm:w-11  h-8 sm:h-11  border-2 border-white rounded-full flex items-center justify-center",
        {
          "-left-8 ml-[22px] md:ml-5 sm:-left-14": isLeftBtn,
          "-right-8 mr-[22px] md:mr-5 sm:-right-14": !isLeftBtn,
          hidden: isHidden,
        }
      )}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {isLeftBtn ? (
        <ArrowLeftIcon className="w-4 sm:w-8" />
      ) : (
        <ArrowRightIcon className="w-4 sm:w-8" />
      )}
    </button>
  );
};

export default PortfolioButton;
