import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  LockClosedIcon,
  UserGroupIcon,
  CogIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import cx from "classnames";
import {
  BadgeCheckIcon,
  ShoppingCartIcon,
  CalendarIcon,
  LocationMarkerIcon,
  FlagIcon,
  StatusOnlineIcon,
  TicketIcon as EventIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { TicketIcon } from "@heroicons/react/outline";
import { ExpertOfferListItem } from "types/user";
import {
  getUpdatedPrice,
  getPriceType,
  getRemainingTime,
  clientTimeZone,
  cloudfront,
  makeUrlsClickable,
} from "utils";
import OfferAttachment from "components/OfferAttachment/OfferAttachment";
import { HourglassIcon } from "components/Icons";
import { formattedDate } from "utils/timeZoneUtils";
import Icon from "components/Icons/ShareIcon/ShareIcon";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import OfferCardMenu from "components/OfferCardMenu/OfferCardMenu";

const EventExpertCard = ({
  publicId,
  isBusinessType = false,
  loading,
  isMyProfile = false,
  children,
  offerType = "offer",
  timeZonePublic,
  isSoldEnabled,
  offerSlug,
  offers,
  handleCardClick,
  name,
  IconComponent,
  subscribed,
  offerPrice,
  levels,
  daysUntilExpiration,
  expiration,
  sold,
  joined,
  sections,
  cover,
  countryCurrency,
  trial,
  telegramLink,
  handleCopy,
  sharing,
  editLink,
  manageLink,
  isMyProfileis,
  isPublicType,
  isTelegramConnected,
  compareDates,
  eventDate,
  timeZone,
  isCurrentTimeInRange,
  eventDateEnd,
  eventType,
  timezone,
  city,
  isBoughtEvent,
  isFreeOffer,
  timeFormat,
  instoreids,
  isSoldOut,
  price,
  seatsLeft,
  mediaUrl,
  attachemntUrl,
  adaptivePlaylistCover,
  communityTitle,
  cardSize,
  updateOfferStatus,
  offerId,
  isEnabled,
  onDeleteClick,
  isPreview,
}: {
  offerSlug?: string;
  offers: ExpertOfferListItem[];
  publicId: string;
  type?: "default" | "public";
  isBusinessType?: boolean;
  loading?: boolean;
  children;
  isMyProfile?: boolean;
  offerType?: string;
  eventType?: string;
  eventDate?: string;
  eventDateEnd?: string;
  participants?: number;
  sold?: number;
  timeZonePublic?: string;
  isSoldEnabled?: boolean;
  handleCardClick;
  name;
  IconComponent;
  subscribed;
  offerPrice;
  levels;
  daysUntilExpiration;
  expiration;
  joined;
  sections;
  cover;
  countryCurrency;
  trial;
  telegramLink;
  handleCopy;
  sharing;
  editLink;
  manageLink;
  isMyProfileis;
  isPublicType;
  isTelegramConnected;
  compareDates;
  timeZone;
  isCurrentTimeInRange;
  timezone;
  city;
  isBoughtEvent;
  isFreeOffer;
  timeFormat;
  instoreids;
  isSoldOut;
  price;
  seatsLeft;
  mediaUrl;
  attachemntUrl;
  adaptivePlaylistCover;
  communityTitle?: string;
  cardSize?: string;
  updateOfferStatus?;
  offerId?: string;
  isEnabled?: boolean;
  onDeleteClick?;
  isPreview?: boolean;
}) => {
  const isEvent = offerType === "event";
  const { t } = useTranslation();
  const isLarge = cardSize === "large";
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      <Fragment key={offerSlug}>
        <section
          data-testid="offer-card"
          className={cx(
            "mt-5 p-4 shadow rounded-t-lg bg-slate-50 cursor-pointer relative",
            {
              "rounded-b-lg mb-5 md:mb-6": !isBusinessType,
            }
          )}
          onClick={handleCardClick}
        >
          <div>
            <div>
              {isLarge ? (
                <a
                  href={`${publicId}/${offerType}/${offerSlug}`}
                  target={isBusinessType || isPreview ? `_blank` : `_self`}
                  rel="noopener noreferrer"
                >
                  <div className="flex flex-row justify-between items-center gap-4">
                    <h2
                      data-is-link="true"
                      className={cx(
                        "items-justify text-base font-semibold leading-5 text-slate-900",
                        {
                          "basis-auto": isEvent,
                        }
                      )}
                    >
                      {name}
                    </h2>
                    {!isPublicType ? (
                      !compareDates(eventDate, timeZone) ? (
                        <div className="basis-full md:basis-auto justify-end text-right ml-auto block flex">
                          <button className="rounded-full px-4 py-2 text-xs font-semibold  bg-yellow-300 items-center flex">
                            <HourglassIcon
                              color="black"
                              className="shrink-0 w-4 h-4"
                            />
                            <span>{getRemainingTime(eventDate, timeZone)}</span>
                          </button>
                        </div>
                      ) : isCurrentTimeInRange(
                          eventDate,
                          eventDateEnd,
                          timeZone
                        ) ? (
                        <div className="basis-full md:basis-auto justify-end text-right ml-auto block flex">
                          <button className="rounded-full px-4 py-2 text-xs font-semibold  bg-green-500 items-center flex">
                            <StatusOnlineIcon className="shrink-0 w-4 h-4 text-slate-50 mr-1" />
                            <span className="text-white">
                              {t("eventIsLife")}
                            </span>
                          </button>
                        </div>
                      ) : (
                        <div className="text-right ml-auto block md:flex lg:flex">
                          <button className="rounded-full px-4 py-2 text-xs font-semibold  bg-slate-100 items-center flex">
                            <FlagIcon className="shrink-0 w-4 h-4 mr-1" />
                            <span>{t("passed")}</span>
                          </button>
                        </div>
                      )
                    ) : null}
                    {isPublicType ? (
                      !compareDates(eventDate, timeZonePublic) ? (
                        <div className="text-right ml-auto block md:flex lg:flex">
                          <button className="rounded-full px-4 py-2 text-xs font-semibold  bg-yellow-300 items-center flex">
                            <HourglassIcon
                              color="black"
                              className="shrink-0 w-4 h-4 mr-1"
                            />
                            <span>
                              {getRemainingTime(eventDate, timeZonePublic)}
                            </span>
                          </button>
                        </div>
                      ) : isCurrentTimeInRange(
                          eventDate,
                          eventDateEnd,
                          timeZonePublic
                        ) ? (
                        <div className="md:mb-0 xl:m-0 lg:mb-0 text-right ml-auto block md:flex lg:flex">
                          <button className="rounded-full px-4 py-2 text-xs font-semibold  bg-green-500 items-center flex">
                            <StatusOnlineIcon className="shrink-0 w-4 h-4 text-slate-50 mr-1" />
                            <span className="text-white">
                              {t("eventIsLife")}
                            </span>
                          </button>
                        </div>
                      ) : (
                        <div className="md:mb-0 xl:m-0 lg:mb-0 text-right ml-auto block md:flex lg:flex">
                          <button className="rounded-full px-4 py-2 text-xs font-semibold  bg-slate-100 items-center flex">
                            <FlagIcon className="shrink-0 w-4 h-4 mr-1" />
                            <span>{t("passed")}</span>
                          </button>
                        </div>
                      )
                    ) : null}
                  </div>
                </a>
              ) : null}

              <div className="flex justify-between gap-3 md:gap-4">
                {!isBoughtEvent &&
                !isLarge &&
                isMobileDevice &&
                cover !== null ? (
                  <div className="">
                    <OfferAttachment
                      attachemntUrl={cover !== null ? cloudfront + cover : null}
                      mediaUrl={mediaUrl}
                      adaptivePlaylistCover={adaptivePlaylistCover}
                      cover={true}
                      isRounded={true}
                    />
                  </div>
                ) : null}

                <div>
                  {" "}
                  {!isLarge ? (
                    <a
                      href={`${publicId}/${offerType}/${offerSlug}`}
                      target={isBusinessType || isPreview ? `_blank` : `_self`}
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-row justify-between items-center gap-4">
                        <h2
                          data-is-link="true"
                          className={cx(
                            "items-justify text-base font-semibold leading-5 text-slate-900 whitespace-normal break-word  xl:whitespace-nowrap xl:break-normal xl:max-w-none",
                            {
                              hidden: isLarge,
                              "lg:max-w-[400px] max-w-[121px]":
                                !isBoughtEvent && cover !== null,
                              "basis-auto": isEvent,
                            }
                          )}
                        >
                          {name}
                        </h2>
                      </div>
                    </a>
                  ) : null}
                  <div
                    className={cx(
                      "py-3 flex flex-wrap gap-x-3 gap-y-1 font-medium text-sm text-slate-500",
                      {
                        "!block space-y-2": !isLarge && !isMobileDevice,
                      }
                    )}
                  >
                    <div className="flex gap-2">
                      {isLarge || (!isLarge && !isMobileDevice) ? (
                        <span className="flex items-center">
                          {isEvent && (
                            <EventIcon className="w-5 h-5 mr-1 text-slate-500" />
                          )}
                          <span className="first-letter:uppercase">
                            {getPriceType("event")}
                          </span>
                        </span>
                      ) : null}

                      {isBoughtEvent ? (
                        <span className="flex items-center whitespace-nowrap text-emerald-500">
                          <BadgeCheckIcon className="w-5 h-5 mr-1" />
                          {isFreeOffer ? t("added") : t("purchased")}
                        </span>
                      ) : (
                        <span className="flex items-center whitespace-nowrap">
                          <>
                            <div>
                              <IconComponent className="shrink-0 w-5 h-5 mr-1 bg-slate-500 text-white" />
                            </div>
                          </>
                          {subscribed !== null && !isEvent
                            ? offerPrice
                            : getUpdatedPrice(levels[0]?.price.toString())}
                        </span>
                      )}
                    </div>

                    <>
                      {isLarge && (
                        <span className="flex md:items-center xl:items-center">
                          <CalendarIcon className="shrink-0 h-5 w-5 mr-1 text-slate-500" />
                          <span>
                            {eventType === "OnlineEvent" ||
                            eventType === "OnlineTelegramEvent" ? (
                              <Tippy
                                content={
                                  <span>
                                    {formattedDate(
                                      eventDate,
                                      eventDateEnd,
                                      timeFormat,
                                      timezone || clientTimeZone
                                    )}
                                    {eventType === "OnlineEvent" ||
                                    eventType === "OnlineTelegramEvent" ? (
                                      <span className="ml-1">({city})</span>
                                    ) : null}
                                  </span>
                                }
                                className="bg-black rounded-lg tippy-wrapper"
                                placement="bottom"
                              >
                                <div className="cursor-pointer">
                                  {formattedDate(
                                    eventDate,
                                    eventDateEnd,
                                    timeFormat,
                                    timeZone || clientTimeZone
                                  )}
                                </div>
                              </Tippy>
                            ) : (
                              <div>
                                {formattedDate(
                                  eventDate,
                                  eventDateEnd,
                                  timeFormat,
                                  timezone
                                )}
                                {eventType === "OfflineEvent" ? (
                                  <span className="ml-1">({city})</span>
                                ) : null}
                              </div>
                            )}
                          </span>
                        </span>
                      )}

                      <div className="flex gap-2">
                        {" "}
                        {!isMobileDevice && (
                          <span className="flex items-center">
                            {eventType === "OnlineEvent" ||
                            eventType === "OnlineTelegramEvent" ? (
                              <StatusOnlineIcon className="shrink-0 h-5 w-5 mr-1 text-slate-500" />
                            ) : (
                              <LocationMarkerIcon className="shrink-0 h-5 w-5 mr-1 text-slate-500" />
                            )}

                            <span>
                              {eventType === "OfflineEvent"
                                ? t("offline")
                                : t("online")}
                            </span>
                          </span>
                        )}
                        {isSoldEnabled ? (
                          <>
                            <span className="flex items-center custom-dark-blue">
                              <ShoppingCartIcon className="w-5 h-5 mr-1 text-custom-dark-blue" />
                              <span className="flex items-center custom-dark-blue">
                                {sold}
                              </span>
                            </span>
                          </>
                        ) : null}
                      </div>
                    </>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {!isBoughtEvent && !isLarge && !isMobileDevice ? (
                    <div>
                      <OfferAttachment
                        attachemntUrl={
                          cover !== null ? cloudfront + cover : null
                        }
                        mediaUrl={mediaUrl}
                        adaptivePlaylistCover={adaptivePlaylistCover}
                        cover={true}
                        isRounded={true}
                      />
                    </div>
                  ) : null}
                  {!isLarge ? (
                    <ChevronRightIcon
                      className={cx(
                        "w-8 h-8 shrink-0 relative md:sticky text-slate-400"
                      )}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {isLarge && <div className="border-b border-gray-100 mt-3 mb-4" />}

          <>
            {isBoughtEvent && eventType === "OfflineEvent" && isLarge ? (
              <div className="flex flex-row flex-wrap gap-6 mb-6">
                {instoreids?.map((attachmentUrl, i) => (
                  <OfferAttachment
                    key={i}
                    iconSize={true}
                    attachemntUrl={attachmentUrl}
                    fileName={`Ticket ${i + 1}.pdf`}
                  />
                ))}
              </div>
            ) : null}
            {isLarge ? (
              !isBoughtEvent ? (
                <OfferAttachment
                  attachemntUrl={cover !== null ? cloudfront + cover : null}
                  mediaUrl={mediaUrl}
                  adaptivePlaylistCover={adaptivePlaylistCover}
                  cover={true}
                />
              ) : (
                <div className="mb-4">
                  {sections?.link ? (
                    <>
                      <span className="flex text-slate-500 text-sm">
                        {t("link") + ":"}
                      </span>
                      <span className="flex whitespace-pre-line mb-4 text-sm">
                        {makeUrlsClickable(sections?.link)}
                      </span>
                    </>
                  ) : null}
                  {sections?.location ? (
                    <>
                      <span className="flex text-slate-500">
                        {t("location") + ":"}
                      </span>
                      <span className="flex whitespace-pre-line mb-4">
                        {makeUrlsClickable(sections?.location)}
                      </span>
                    </>
                  ) : null}

                  {sections?.address ? (
                    <>
                      <span className="flex text-slate-500">
                        {t("address") + ":"}
                      </span>
                      <span className="flex mb-4">
                        {makeUrlsClickable(sections?.address)}
                      </span>
                    </>
                  ) : null}
                  {(joined === null && isTelegramConnected) ||
                  eventType !== "OnlineTelegramEvent" ? (
                    <div
                      className="relative whitespace-pre-line text-sm text-justify after:block after:bg-gradient-to-b
    after:absolute after:inset-0 after:from-transparent after:via-transparent after:to-white after:h-44 max-h-40 pb-4 overflow-hidden"
                    >
                      {makeUrlsClickable(sections?.details)}
                    </div>
                  ) : null}

                  {eventType === "OnlineTelegramEvent" &&
                  isTelegramConnected &&
                  joined === null ? (
                    <div className="flex-col min-w-[260px] md:min-w-[300px] mx-auto max-w-fit justify-center mb-4">
                      <Button className="text-sm min-w-[260px] md:min-w-[300px] justify-center">
                        {t("joinEvent")} {communityTitle}
                      </Button>
                    </div>
                  ) : null}
                  {eventType === "OnlineTelegramEvent" &&
                  isTelegramConnected ? (
                    <div
                      className={cx(
                        "text-sm mb-4 whitespace-pre-line break-words"
                      )}
                    >
                      {joined === "joined"
                        ? t("currentlyMember", { title: communityTitle })
                        : joined === "kicked"
                        ? t("notCurrentlyMember", { title: communityTitle })
                        : null}
                    </div>
                  ) : null}

                  {(joined === null && isTelegramConnected) ||
                  eventType !== "OnlineTelegramEvent" ? (
                    <OfferAttachment
                      attachemntUrl={
                        sections?.attachments?.[0]?.link === undefined &&
                        sections?.attachments?.[0]?.inStoreId
                          ? cloudfront + sections?.attachments?.[0]?.inStoreId
                          : sections?.attachments?.[0]?.link
                      }
                      fileName={
                        sections?.attachments?.[0]?.fileName === undefined
                          ? sections?.attachments?.[0]?.inStoreId
                              .split("_")
                              .slice(1)
                              .join("_")
                          : sections?.attachments?.[0]?.fileName
                      }
                      adaptivePlaylist={
                        sections?.attachments[0]?.adaptivePlaylist
                      }
                      section={true}
                      isBought={isBoughtEvent}
                    />
                  ) : null}
                </div>
              )
            ) : null}
          </>

          {!isBoughtEvent &&
          (cover ||
            (sections && sections[0]?.attachments?.[0]?.link) ||
            attachemntUrl ||
            mediaUrl)
            ? isLarge && <div className="border-b border-gray-100 mt-6 mb-6" />
            : null}
          {isLarge ? (
            isPublicType && !compareDates(eventDateEnd, timeZonePublic) ? (
              <>
                <div className="max-w-[270px] md:max-w-[300px] mx-auto">
                  <div
                    className={cx(
                      "justify-center items-center gap-2 w-full  border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400",
                      {
                        "bg-landing-red text-white hover:bg-hover-landing-red":
                          isEvent && !isSoldOut(levels),
                        "bg-slate-100 hover:bg-slate-300 text-black":
                          isSoldOut(levels) && isEvent,
                      }
                    )}
                  >
                    {compareDates(
                      eventDateEnd,
                      timeZone ? timeZone : timeZonePublic
                    ) ? null : (
                      <>
                        <TicketIcon
                          className={`w-6 h-6  ${
                            isSoldOut(levels) ? "text-black" : "text-slate-50"
                          }`}
                        />
                        <span className="truncate">
                          {isFreeOffer
                            ? `${t("attendForfree")}`
                            : !isSoldOut(levels)
                            ? `${t("buyFor")} ${countryCurrency?.symbol}${
                                levels[0]?.price
                              }`
                            : `${t("soldOut")}`}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Tippy
                content={
                  <p className="inline-block p-1 text-white text-center text-xs font-medium">
                    {t("bookingOfferWarning")}
                  </p>
                }
                disabled={!isBusinessType}
                className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
                placement="bottom"
              >
                {compareDates(
                  eventDateEnd,
                  timeZone ? timeZone : timeZonePublic
                ) ? null : eventType !== "OnlineTelegramEvent" ||
                  (eventType === "OnlineTelegramEvent" && !isBoughtEvent) ? (
                  <div className="max-w-fit min-w-[260px] md:min-w-[300px] mx-auto flex-col justify-center">
                    <div
                      className={cx(
                        "justify-center items-center gap-2 w-full text-white border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400",
                        {
                          "pointer-events-none": isBusinessType,
                          "bg-slate-900 hover:bg-slate-800":
                            isBoughtEvent && isEvent,
                          "bg-landing-red hover:bg-hover-landing-red":
                            !isBoughtEvent && isEvent && !isSoldOut(levels),
                          "bg-slate-100 hover:bg-slate-300":
                            isSoldOut(levels) && isEvent && !isBoughtEvent,
                        }
                      )}
                    >
                      {isEvent ? (
                        isBoughtEvent &&
                        eventType === "OfflineEvent" &&
                        !isSoldOut(levels) ? (
                          <div className="flex gap-2">
                            <TicketIcon className="w-6 h-6 text-slate-50" />
                            <span>{t("buyMore")}</span>
                          </div>
                        ) : (isBoughtEvent && eventType === "OnlineEvent") ||
                          (eventType === "OfflineEvent" &&
                            isSoldOut(levels) &&
                            isBoughtEvent) ? (
                          <span>{t("view")}</span>
                        ) : isSoldOut(levels) && !isBoughtEvent ? (
                          <div className="flex items-center">
                            <TicketIcon className="w-6 h-6 text-black" />
                            <span className="font-bold text-black pl-2 text-sm">
                              {t("soldOut")}
                            </span>
                          </div>
                        ) : (
                          <>
                            <TicketIcon className="w-6 h-6 text-slate-50" />

                            <span className="truncate">
                              {isFreeOffer
                                ? t("attendForfree")
                                : `${t("buyFor")} ${countryCurrency?.symbol}${
                                    levels[0]?.price
                                  }`}
                            </span>
                          </>
                        )
                      ) : null}
                    </div>
                  </div>
                ) : !isTelegramConnected ? (
                  <>
                    <div className="text-sm mb-4 text-center">
                      {t("connectYourTelegram")}
                    </div>
                    <div className="flex-col min-w-[260px] md:min-w-[300px] mx-auto max-w-fit justify-center mt-4">
                      <Button
                        onClick={() =>
                          window.open(
                            telegramLink,
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                        iconAfter={true}
                        icon={
                          <img
                            src="/img/telegram-support.svg"
                            className="w-full h-full"
                          />
                        }
                        className="text-sm min-w-[260px] md:min-w-[300px] justify-center"
                      >
                        {t("connectTelegramBot")}
                      </Button>
                    </div>
                  </>
                ) : null}
              </Tippy>
            )
          ) : null}

          {isLarge &&
          !compareDates(eventDateEnd, timeZone ? timeZone : timeZonePublic) &&
          !isBoughtEvent &&
          isSoldEnabled ? (
            <div className="flex justify-center items-center mt-4 mb-2">
              <UserGroupIcon className="w-5 h-5 mr-1" />
              <span className="text-sm">{seatsLeft(levels)}</span>
            </div>
          ) : null}
          {isLarge &&
          !compareDates(eventDateEnd, timeZone ? timeZone : timeZonePublic) &&
          isBoughtEvent &&
          eventType === "OfflineEvent" &&
          !isSoldOut(levels) &&
          isSoldEnabled ? (
            <div className="flex justify-center items-center mt-4 mb-2">
              <UserGroupIcon className="w-5 h-5 mr-1" />
              <span className="text-sm">{seatsLeft(levels)}</span>
            </div>
          ) : null}

          {isEvent &&
          compareDates(eventDateEnd, timeZone ? timeZone : timeZonePublic) &&
          !isBoughtEvent &&
          isLarge ? (
            <div className="max-w-[270px] md:max-w-[300px] mx-auto">
              <div className="justify-center items-center gap-2 w-full text-white border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-slate-100">
                <TicketIcon className="w-6 h-6 text-black" />
                <span className="text-black text-sm">{t("eventIsOver")}</span>
              </div>
            </div>
          ) : null}

          {sharing === "link" ? (
            <LockClosedIcon className="w-5 h-5 absolute top-3.5 right-3.5 text-slate-500" />
          ) : null}
        </section>
        {isBusinessType ? (
          <div>
            <div className="relative mb-5 gap-2 md:gap-6 md:mb-6 p-4 border-t border-slate-200 bg-slate-100 flex items-center justify-center shadow rounded-b-lg cursor-pointer">
              <Link className="flex items-center" to={editLink}>
                <PencilIcon className="w-5 h-5 mr-1 text-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("buttonEdit")}
                </span>
              </Link>
              {isEvent && (
                <Link className="flex items-center" to={manageLink}>
                  <CogIcon className="h-5 w-5 mr-1 text-slate-400" />
                  <span className="text-slate-400 text-sm font-medium">
                    {t("manage")}
                  </span>
                </Link>
              )}

              <div
                onClick={() => handleCopy(offerSlug)}
                className="flex items-center clipboard"
              >
                <Icon className="w-5 h-5 mr-1 stroke-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("share")}
                </span>
              </div>
              <div className="absolute right-14 z-50">
                <OfferCardMenu
                  offerId={offerId}
                  type="event"
                  offerSlug={offerSlug}
                  publicId={publicId}
                  enabled={isEnabled}
                  updateOfferStatus={updateOfferStatus}
                  onDeleteClick={onDeleteClick}
                  eventType={eventType}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    </>
  );
};

export default EventExpertCard;
