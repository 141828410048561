import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  // LockClosedIcon,
  FireIcon,
  CogIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import cx from "classnames";
import {
  BadgeCheckIcon,
  ShoppingCartIcon,
  FireIcon as CommunityIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { AccessIcon } from "components/Icons";
import { ExpertOfferListItem } from "types/user";
import {
  getUpdatedPrice,
  getPriceType,
  cloudfront,
  makeUrlsClickable,
} from "utils";
import OfferAttachment from "components/OfferAttachment/OfferAttachment";
import Button from "components/Button/Button";
import { HourglassIcon } from "components/Icons";
import Icon from "components/Icons/ShareIcon/ShareIcon";
import { useTranslation } from "react-i18next";
import OfferCardMenu from "components/OfferCardMenu/OfferCardMenu";

const CommunityExpertCard = ({
  publicId,
  isBusinessType = false,
  loading,
  isMyProfile = false,
  children,
  offerType = "offer",
  timeZonePublic,
  isSoldEnabled,
  offerSlug,
  offers,
  handleCardClick,
  name,
  IconComponent,
  subscribed,
  offerPrice,
  levels,
  daysUntilExpiration,
  expiration,
  sold,
  joined,
  sections,
  cover,
  countryCurrency,
  trial,
  telegramLink,
  handleCopy,
  sharing,
  editLink,
  manageLink,
  isMyProfileis,
  isPublicType,
  isTelegramConnected,
  mediaUrl,
  inviteLink,
  title,
  adaptivePlaylistCover,
  cardSize,
  updateOfferStatus,
  offerId,
  isEnabled,
  onDeleteClick,
  isPreview,
}: {
  offerSlug?: string;
  offers: ExpertOfferListItem[];
  publicId: string;
  type?: "default" | "public";
  isBusinessType?: boolean;
  loading?: boolean;
  children;
  isMyProfile?: boolean;
  offerType?: string;
  eventType?: string;
  eventDate?: string;
  eventDateEnd?: string;
  participants?: number;
  sold?: number;
  timeZonePublic?: string;
  isSoldEnabled?: boolean;
  handleCardClick;
  name;
  IconComponent;
  subscribed;
  offerPrice;
  levels;
  daysUntilExpiration;
  expiration;
  joined;
  sections;
  cover;
  countryCurrency;
  trial;
  telegramLink;
  handleCopy;
  sharing;
  editLink;
  manageLink;
  isMyProfileis;
  isPublicType;
  isTelegramConnected;
  mediaUrl;
  inviteLink;
  title;
  adaptivePlaylistCover;
  cardSize?: string;
  updateOfferStatus?;
  offerId?: string;
  isEnabled?: boolean;
  onDeleteClick?;
  isPreview?: boolean;
}) => {
  const isCommunity = offerType === "community";
  const { t } = useTranslation();
  const isFreeCommunity = levels[0].price === 0;
  const isLarge = cardSize === "large";
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      <Fragment key={offerSlug}>
        <section
          className={cx(
            "mt-5 p-4 shadow rounded-t-lg bg-slate-50 cursor-pointer relative",
            {
              "rounded-b-lg mb-5 md:mb-6": !isBusinessType,
              "py-4": isLarge,
            }
          )}
          onClick={handleCardClick}
        >
          <div data-testid="offer-card">
            <div>
              <a
                className={cx({
                  hidden: !isLarge,
                })}
                href={`${publicId}/${offerType}/${offerSlug}`}
                target={isBusinessType || isPreview ? `_blank` : `_self`}
                rel="noopener noreferrer"
              >
                <div className="flex flex-row justify-between items-center gap-4">
                  <h2
                    data-is-link="true"
                    className={cx(
                      "items-justify text-base font-semibold leading-5 text-slate-900"
                    )}
                  >
                    {name}
                  </h2>
                </div>
              </a>
              {/* <div className="py-3 flex flex-wrap gap-x-3 gap-y-1 font-medium text-sm text-slate-500">
                <span className="flex items-center">
                  <CommunityIcon className="w-5 h-5 mr-1 text-slate-500" />
                  <span className="first-letter:uppercase">
                    {getPriceType("community")}
                  </span>
                </span>

                <span className="flex items-center whitespace-nowrap">
                  <>
                    <div>
                      <IconComponent className="shrink-0 w-5 h-5 mr-1 bg-slate-500 text-white" />
                    </div>
                  </>
                  {subscribed !== null
                    ? offerPrice
                    : getUpdatedPrice(levels[0]?.price.toString())}
                </span>

                {daysUntilExpiration === null &&
                (subscribed !== null
                  ? expiration === 0
                  : levels[0]?.expiration === "0") ? (
                  <span className="flex items-center whitespace-nowrap -ml-2.5">
                    ({t("unlimited")})
                  </span>
                ) : (
                  <span className="flex items-center whitespace-nowrap -ml-2.5">
                    (
                    {(subscribed !== null
                      ? expiration
                      : levels[0]?.expiration) +
                      " " +
                      t("days")}
                    )
                  </span>
                )}

                {isCommunity &&
                !isPublicType &&
                !isMyProfileis &&
                subscribed !== null ? (
                  <span
                    className={`flex items-center whitespace-nowrap ${
                      subscribed === false && daysUntilExpiration !== null
                        ? "text-gray-500"
                        : "text-emerald-500"
                    }`}
                  >
                    <BadgeCheckIcon className="w-5 h-5 mr-1" />
                    {subscribed === true || daysUntilExpiration === null
                      ? t("subscribed")
                      : subscribed === false && daysUntilExpiration !== null
                      ? t("usubscribed")
                      : null}
                  </span>
                ) : null}

                {isCommunity &&
                !isPublicType &&
                !isMyProfileis &&
                subscribed !== null ? (
                  <span className="flex items-center whitespace-nowrap text-slate-500">
                    <HourglassIcon className="shrink-0 w-5 h-5" />
                    <span className="ml-1">
                      {isCommunity && !isMyProfileis
                        ? `${
                            daysUntilExpiration <= 0 ? 0 : daysUntilExpiration
                          } ${t("daysLeft")}`
                        : `${
                            subscribed !== null
                              ? expiration
                              : levels[0]?.expiration
                          } ${t("days")}`}
                    </span>
                  </span>
                ) : null}

                {isSoldEnabled ? (
                  <>
                    <span className="flex items-center custom-dark-blue">
                      <ShoppingCartIcon className="w-5 h-5 mr-1 text-custom-dark-blue" />
                      <span className="flex items-center custom-dark-blue">
                        {sold}
                      </span>
                    </span>
                  </>
                ) : null}
              </div> */}
              <div className="flex justify-between gap-3 md:gap-4">
                {!isLarge && isMobileDevice && cover !== null ? (
                  (joined === null && subscribed === true) ||
                  subscribed !== null ? null : (
                    <div>
                      {" "}
                      <OfferAttachment
                        attachemntUrl={
                          cover !== null ? cloudfront + cover : null
                        }
                        mediaUrl={mediaUrl}
                        adaptivePlaylistCover={adaptivePlaylistCover}
                        cover={true}
                        isRounded={true}
                      />
                    </div>
                  )
                ) : null}

                <div>
                  {" "}
                  {!isLarge ? (
                    <a
                      href={`${publicId}/${offerType}/${offerSlug}`}
                      target={isBusinessType || isPreview ? `_blank` : `_self`}
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-row justify-between items-center gap-4">
                        <h2
                          data-is-link="true"
                          className={cx(
                            "items-justify text-base font-semibold leading-5 text-slate-900 whitespace-normal break-word xl:whitespace-nowrap xl:break-normal xl:max-w-none",
                            {
                              hidden: isLarge,
                              "lg:max-w-[400px] max-w-[121px]": cover !== null,
                            }
                          )}
                        >
                          {name}
                        </h2>
                      </div>
                    </a>
                  ) : null}
                  <div
                    className={cx(
                      "py-3 flex flex-wrap gap-x-3 gap-y-1 font-medium text-sm text-slate-500",
                      {
                        "!block space-y-2": !isLarge && !isMobileDevice,
                      }
                    )}
                  >
                    <div className="flex gap-2">
                      {isLarge || (!isLarge && !isMobileDevice) ? (
                        <span className="flex items-center">
                          <CommunityIcon className="w-5 h-5 mr-1 text-slate-500" />
                          <span className="first-letter:uppercase">
                            {getPriceType("community")}
                          </span>
                        </span>
                      ) : null}
                      <span className="flex items-center whitespace-nowrap">
                        <>
                          <div>
                            <IconComponent className="shrink-0 w-5 h-5 mr-1 bg-slate-500 text-white" />
                          </div>
                        </>
                        {subscribed !== null
                          ? offerPrice
                          : getUpdatedPrice(levels[0]?.price.toString())}
                      </span>
                    </div>

                    <div className="flex gap-2">
                      {!isMobileDevice ? (
                        daysUntilExpiration === null &&
                        (subscribed !== null
                          ? expiration === 0
                          : levels[0]?.expiration === "0") ? (
                          <span
                            className={cx(
                              "flex items-center whitespace-nowrap",
                              { "-ml-2.5": isLarge }
                            )}
                          >
                            ({t("unlimited")})
                          </span>
                        ) : (
                          <span
                            className={cx(
                              "flex items-center whitespace-nowrap",
                              { "-ml-2.5": isLarge }
                            )}
                          >
                            (
                            {(subscribed !== null
                              ? expiration
                              : levels[0]?.expiration) +
                              " " +
                              t("days")}
                            )
                          </span>
                        )
                      ) : null}

                      {isCommunity &&
                      !isPublicType &&
                      !isMyProfileis &&
                      subscribed !== null ? (
                        <span
                          className={`flex items-center whitespace-nowrap ${
                            subscribed === false && daysUntilExpiration !== null
                              ? "text-gray-500"
                              : "text-emerald-500"
                          }`}
                        >
                          <BadgeCheckIcon className="w-5 h-5 mr-1" />
                          {subscribed === true || daysUntilExpiration === null
                            ? t("subscribed")
                            : subscribed === false &&
                              daysUntilExpiration !== null
                            ? t("usubscribed")
                            : null}
                        </span>
                      ) : null}

                      {isCommunity &&
                      !isPublicType &&
                      !isMyProfileis &&
                      subscribed !== null ? (
                        <span className="flex items-center whitespace-nowrap text-slate-500">
                          <HourglassIcon className="shrink-0 w-5 h-5" />
                          <span className="ml-1">
                            {isCommunity && !isMyProfileis
                              ? `${
                                  daysUntilExpiration <= 0
                                    ? 0
                                    : daysUntilExpiration
                                } ${t("daysLeft")}`
                              : `${
                                  subscribed !== null
                                    ? expiration
                                    : levels[0]?.expiration
                                } ${t("days")}`}
                          </span>
                        </span>
                      ) : null}

                      {isSoldEnabled ? (
                        <>
                          <span className="flex items-center custom-dark-blue">
                            <ShoppingCartIcon className="w-5 h-5 mr-1 text-custom-dark-blue" />
                            <span className="flex items-center custom-dark-blue">
                              {sold}
                            </span>
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {!isLarge && !isMobileDevice && cover !== null ? (
                    (joined === null && subscribed === true) ||
                    subscribed !== null ? null : (
                      <OfferAttachment
                        attachemntUrl={
                          cover !== null ? cloudfront + cover : null
                        }
                        mediaUrl={mediaUrl}
                        adaptivePlaylistCover={adaptivePlaylistCover}
                        cover={true}
                        isRounded={true}
                      />
                    )
                  ) : null}
                  {!isLarge ? (
                    <ChevronRightIcon
                      className={cx(
                        "w-8 h-8 shrink-0 relative md:sticky text-slate-400"
                      )}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {isLarge && <div className="border-b border-gray-100 mt-3 mb-4" />}

          <>
            {isLarge && (
              <div
                className="relative text-sm mt-3 whitespace-pre-line overflow-hidden after:block after:bg-gradient-to-b
                  after:absolute after:inset-0 after:from-transparent after:via-transparent after:to-white after:h-44 max-h-40 mb-3"
              >
                {subscribed === null || isMyProfileis || isPublicType ? null : (
                  <div
                    className={cx(
                      "text-sm mb-0 whitespace-pre-line break-words ",
                      {
                        "mb-0": joined === null,
                      }
                    )}
                  >
                    {joined === null &&
                      subscribed !== null &&
                      isTelegramConnected && (
                        <>
                          <div className="text-sm mb-4">
                            {makeUrlsClickable(sections?.details)}
                          </div>
                          <div className="flex-col min-w-[260px] md:min-w-[300px] mx-auto max-w-fit justify-center mb-4">
                            <Button
                              onClick={() =>
                                window.open(
                                  inviteLink,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                              className="text-sm min-w-[260px] md:min-w-[300px] justify-center"
                            >
                              {t("joinChannel")} {title}
                            </Button>
                          </div>
                        </>
                      )}

                    {isTelegramConnected ? (
                      joined === "joined" ? (
                        t("currentlyMember", {
                          title: title,
                        })
                      ) : joined === "kicked" ? (
                        t("notCurrentlyMember", {
                          title: title,
                        })
                      ) : null
                    ) : (
                      <div className="text-sm text-center">
                        {t("connectYourTelegram")}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {isLarge ? (
              (joined === null && subscribed === true) ||
              subscribed !== null ? null : (
                <OfferAttachment
                  attachemntUrl={cover !== null ? cloudfront + cover : null}
                  mediaUrl={mediaUrl}
                  adaptivePlaylistCover={adaptivePlaylistCover}
                  cover={true}
                />
              )
            ) : null}
          </>
          {subscribed !== null && joined === null ? (
            isTelegramConnected && isLarge ? (
              <div className="mb-4">
                <OfferAttachment
                  attachemntUrl={
                    sections?.attachments?.[0]?.link === undefined &&
                    sections?.attachments?.[0]?.inStoreId
                      ? cloudfront + sections?.attachments?.[0]?.inStoreId
                      : sections?.attachments?.[0]?.link
                  }
                  fileName={
                    sections?.attachments?.[0]?.fileName === undefined
                      ? sections?.attachments?.[0]?.inStoreId
                          .split("_")
                          .slice(1)
                          .join("_")
                      : sections?.attachments?.[0]?.fileName
                  }
                  adaptivePlaylist={
                    sections?.attachments?.[0]?.adaptivePlaylist
                  }
                  section={true}
                />
              </div>
            ) : null
          ) : null}

          {isLarge &&
          (cover || (sections && sections[0]?.attachments?.[0]?.link)) ? (
            <div className="border-b border-gray-100 mt-6 mb-6" />
          ) : null}

          {isLarge ? (
            isPublicType ? (
              <>
                <div className="max-w-[270px] md:max-w-[300px] mx-auto">
                  <div
                    className={cx(
                      "justify-center items-center gap-2 w-full bg-landing-red text-white hover:bg-hover-landing-red border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                    )}
                  >
                    <span className="justify-center items-center  w-full text-white  inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white gap-2">
                      {levels[0]?.expiration > 0 ? (
                        <FireIcon className="w-6 h-6" />
                      ) : (
                        <AccessIcon className="w-5 h-5" />
                      )}

                      {trial > 0
                        ? t("tryForFree")
                        : `${
                            levels[0]?.expiration > 0
                              ? t("subscribeFor")
                              : t("buyAccessFor")
                          } ${countryCurrency?.symbol}${levels[0]?.price}`}
                    </span>
                  </div>
                </div>
                {isCommunity && (
                  <>
                    {trial > 0 && (
                      <span className="text-gray-900 text-sm flex items-start justify-center gap-1 mt-3">
                        {t("trialPeriod")} {trial} {t("days")}
                      </span>
                    )}

                    <p className="flex items-start justify-center gap-1 mt-2">
                      <span className="text-gray-900">
                        {levels[0]?.expiration > 0
                          ? `${t("paymentOccurs")} ${levels[0]?.expiration} ${t(
                              "days"
                            )}.`
                          : t("paymentOccursOneTime")}
                      </span>
                    </p>
                  </>
                )}
              </>
            ) : (
              <Tippy
                content={
                  <p className="inline-block p-1 text-white text-center text-xs font-medium">
                    {t("bookingOfferWarning")}
                  </p>
                }
                disabled={!isBusinessType}
                className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
                placement="bottom"
              >
                <div className="max-w-fit min-w-[260px] md:min-w-[300px] mx-auto flex-col justify-center">
                  <>
                    <div className="max-w-fit min-w-[260px] md:min-w-[300px] flex-col justify-center">
                      {subscribed === true && daysUntilExpiration !== null ? (
                        <>
                          {isTelegramConnected ? (
                            <span className="justify-center cursor-pointer items-center gap-2 w-full text-slate-400 border border-slate-400 border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 ">
                              {t("unsubscribe")}
                            </span>
                          ) : (
                            <Button
                              onClick={() =>
                                window.open(
                                  telegramLink,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                              iconAfter={true}
                              icon={
                                <img
                                  src="/img/telegram-support.svg"
                                  className="w-full h-full"
                                  alt="telegram support"
                                />
                              }
                              className="text-sm min-w-[260px] md:min-w-[300px] justify-center"
                            >
                              {t("connectTelegramBot")}
                            </Button>
                          )}
                        </>
                      ) : subscribed === false &&
                        daysUntilExpiration !== null ? (
                        isTelegramConnected ? (
                          <>
                            <span className="justify-center items-center gap-2 w-full text-white border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white bg-landing-red hover:bg-hover-landing-red">
                              <FireIcon className="w-6 h-6" />
                              {t("subscribeAgain")}
                            </span>
                            <p className="flex items-start justify-center gap-1 mt-3">
                              <span className="text-gray-900">
                                {expiration
                                  ? t("paymentOccurs") +
                                    " " +
                                    expiration +
                                    " " +
                                    t("days") +
                                    "."
                                  : t("paymentOccursOneTime")}
                              </span>
                            </p>
                          </>
                        ) : (
                          <Button
                            onClick={() =>
                              window.open(
                                telegramLink,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                            iconAfter={true}
                            icon={
                              <img
                                src="/img/telegram-support.svg"
                                className="w-full h-full"
                                alt="telegram support"
                              />
                            }
                            className="text-sm"
                          >
                            {t("connectTelegramBot")}
                          </Button>
                        )
                      ) : daysUntilExpiration === null &&
                        subscribed !== null ? (
                        isTelegramConnected ? null : (
                          <Button
                            onClick={() =>
                              window.open(
                                telegramLink,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                            iconAfter={true}
                            icon={
                              <img
                                src="/img/telegram-support.svg"
                                className="w-full h-full"
                                alt="telegram support"
                              />
                            }
                            className="text-sm min-w-[260px] md:min-w-[300px] justify-center"
                          >
                            {t("connectTelegramBot")}
                          </Button>
                        )
                      ) : (
                        <div>
                          <span className="justify-center items-center gap-2 w-full text-white border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white bg-landing-red hover:bg-hover-landing-red">
                            {levels[0]?.expiration > 0 ? (
                              <FireIcon className="w-6 h-6" />
                            ) : (
                              <AccessIcon className="w-5 h-5" />
                            )}

                            {trial > 0
                              ? t("tryForFree")
                              : isFreeCommunity && levels[0]?.expiration > 0
                              ? t("subscribeForFree")
                              : `${
                                  levels[0]?.expiration > 0
                                    ? t("subscribeFor")
                                    : t("buyAccessFor")
                                } ${countryCurrency?.symbol}${
                                  levels[0]?.price
                                }`}
                          </span>
                          {trial > 0 && (
                            <span className="text-gray-900 text-sm flex items-start justify-center gap-1 mt-3">
                              {t("trialPeriod")} {trial} {t("days")}
                            </span>
                          )}
                          <p className="flex items-start justify-center gap-1 mt-2">
                            <span className="text-gray-900">
                              {levels[0]?.expiration > 0
                                ? t("paymentOccurs") +
                                  " " +
                                  levels[0]?.expiration +
                                  " " +
                                  t("days") +
                                  "."
                                : t("paymentOccursOneTime")}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </Tippy>
            )
          ) : null}
        </section>
        {isBusinessType ? (
          <div>
            <div className="relative mb-5 gap-2 md:gap-6 md:mb-6 p-4 border-t border-slate-200 bg-slate-100 flex items-center justify-center shadow rounded-b-lg cursor-pointer">
              <Link className="flex items-center" to={editLink}>
                <PencilIcon className="w-5 h-5 mr-1 text-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("buttonEdit")}
                </span>
              </Link>
              <Link className="flex items-center" to={manageLink}>
                <CogIcon className="h-5 w-5 mr-1 text-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("manage")}
                </span>
              </Link>

              <div
                onClick={() => handleCopy(offerSlug)}
                className="flex items-center clipboard"
              >
                <Icon className="w-5 h-5 mr-1 stroke-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("share")}
                </span>
              </div>

              <div className="absolute right-14 z-50">
                <OfferCardMenu
                  offerId={offerId}
                  type="community"
                  offerSlug={offerSlug}
                  publicId={publicId}
                  enabled={isEnabled}
                  updateOfferStatus={updateOfferStatus}
                  onDeleteClick={onDeleteClick}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    </>
  );
};

export default CommunityExpertCard;
