import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";

type Types =
  | "CHAT"
  | "CHAT_WITH_USER"
  | "FILES"
  | "SESSIONS"
  | "TRANSACTIONS"
  | "RESCHEDULE";
type ContextProps = {
  isOpen: boolean;
  title: string | null;
  id: string | null;
  type: Types;
  data: any;
};

const defaultState: ContextProps = {
  isOpen: false,
  title: "",
  id: "",
  type: null,
  data: {},
};

export const DrawerContext = createContext<
  [ContextProps, Dispatch<SetStateAction<ContextProps>>]
>([defaultState, () => {}]);

function DrawerProvider({ children }: { children: ReactNode }) {
  const state = useState(defaultState);

  return (
    <DrawerContext.Provider value={state}>{children}</DrawerContext.Provider>
  );
}

export const useDrawer = () => {
  const [state, setState] = useContext(DrawerContext);

  useEffect(() => {
    const searchComponent: any = document.getElementById("message");
    if (searchComponent) {
      searchComponent.focus();
    }
  }, [state]);

  function handleOpen({
    title,
    type,
    id,
    data,
  }: {
    title: string;
    type: Types;
    id?: string;
    data?: any;
  }) {
    setState((state) => ({
      ...state,
      isOpen: true,
      title,
      type,
      id: id || "",
      data,
    }));
  }

  function handleClose() {
    if (state.type === "TRANSACTIONS" || state.type === "RESCHEDULE") {
      setState((state) => ({ ...state, data: null, id: null, isOpen: false }));
      return;
    }

    setState((state) => ({ ...state, id: null, isOpen: false }));
  }

  return {
    isOpen: state.isOpen,
    title: state.title,
    type: state.type,
    id: state.id,
    data: state.data,
    handleOpen,
    handleClose,
  };
};

export default DrawerProvider;
