import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import {
  StarIcon,
  LocationMarkerIcon,
  InformationCircleIcon,
  UserCircleIcon,
  EyeIcon,
  DotsVerticalIcon,
  PencilIcon as PencilIconSolid,
} from "@heroicons/react/solid";
import { Link as ReactLink, useHistory } from "react-router-dom";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import {
  ExclamationCircleIcon,
  FireIcon,
  DownloadIcon,
  ThumbUpIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import Image from "next/image";
import { AnimatedSocialIcon } from "react-animated-social-icons";
import cx from "classnames";
import { Expert, Review } from "types/user";
import { useDrawer } from "hooks/DrawerContext";
import {
  getLanguageLabel,
  convertRating,
  getFormattedTimeZone,
  filterTags,
  formatDate,
  getFullName,
  getTopLevelDomain,
  getIconSocialMedia,
  handleClipboard,
} from "utils";
import ReviewItem from "components/ReviewItem/ReviewItem";
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import FollowingBtn from "components/FollowingBtn/FollowingBtn";
import useWindowDimensions from "hooks/useWindowDimensions";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import { getHostName, convertVimeoUrl } from "helpers";
import useLocalStorage from "hooks/useLocalStorage";
import Icon from "components/Icons/ShareIcon/ShareIcon";
// import ChatIcon from "components/Icons/ChatIcon/ChatIcon";
import { useTranslation } from "react-i18next";
import Hls from "hls.js";
import { SingleSessionButton } from "components/LoggedNav/components/SingleSessionButton";
import ShareMenu from "components/ShareMenu/ShareMenu";

declare global {
  interface Window {
    hls: any;
  }
}

interface Props extends Expert {
  fullName: string;
  handleProfileButton: () => void;
  isFollowed?: boolean;
  handleFollow: (e?: any) => void;
  type?: "default" | "modal";
  onOpenAllInfo: () => void;
  followLoading?: boolean;
  viewType?: "private" | "public";
  disabled?: boolean;
  isMyProfile?: boolean;
  headerVideoFile: any;
  headerVideoLink?: any;
  isPlaying: boolean;
  setIsPlaying: (value: boolean | ((prevState: boolean) => boolean)) => void;
  handleNewReview;
  loading?: boolean;
  rating: string;
  reviews: Review[];
  followers: string;
  activeLeaveReview?: boolean;
  isBusinessType?: boolean;
  city: string;
  isMobile?: boolean;
  blobLoading?: boolean;
  currency?: string;
  isPreview?: boolean;
  togglePreview?: () => void;
  setEditType?: ({
    type,
  }: {
    type: "PUBLIC_ID" | "COVER" | "PROFILE_DATA" | "PORTFOLIO";
  }) => void;
}

const ExpertMainInfo = ({
  id,
  avatar,
  fullName,
  occupation,
  timeZone,
  rating,
  isMyProfile = false,
  tags,
  about,
  reviews,
  headerVideoFile,
  headerVideoLink,
  introVideo,
  email,
  phone,
  languages,
  isPlaying,
  setIsPlaying,
  socialNetworks,
  handleNewReview,
  loading,
  isOnline,
  handleProfileButton,
  isFollowed = false,
  handleFollow,
  type = "default",
  onOpenAllInfo,
  followLoading = false,
  viewType = "private",
  disabled = false,
  followers,
  activeLeaveReview,
  isBusinessType = false,
  freeChat,
  publicId,
  city,
  tabs,
  isMobile,
  blobLoading,
  currency,
  isPreview,
  togglePreview,
  setEditType,
}: Props) => {
  const router = useRouter();
  const history = useHistory();
  const { handleOpen } = useDrawer();
  const { width } = useWindowDimensions();
  const isDefault = type === "default";
  const isModal = type === "modal";
  const formattedTimeZone = getFormattedTimeZone(timeZone);
  const expertTags = filterTags(tags);
  const lastReview = reviews?.[reviews.length - 1];
  const isPublic = viewType === "public";
  const isChatOpenInitially = router.query.chat;
  const { t } = useTranslation();

  for (let key in tabs) {
    let lowerCaseValue = tabs[key].toString().toLowerCase();
    tabs[key] =
      lowerCaseValue === "true"
        ? true
        : lowerCaseValue === "false"
        ? false
        : tabs[key];
  }
  const [isChatOpenTrue, setOpenChat] = useLocalStorage(
    `expert-chat-${id}`,
    null
  );
  const [isFollow, setFollow] = useLocalStorage(`expert-follow-${id}`, null);
  useEffect(() => {
    if (isFollow && !isMyProfile && id) {
      handleFollow();
    }
    setFollow(false);
  }, [isFollow, isMyProfile, id]);

  useEffect(() => {
    if (isChatOpenInitially && !isMyProfile) {
      handleChatOpen(null);
    }
  }, [isChatOpenInitially, isMyProfile]);

  useEffect(() => {
    if (isChatOpenTrue && id) {
      handleOpen({
        title: t("buttonChat"),
        type: "CHAT",
        id,
      });
      setOpenChat(false);
    }
  }, [isChatOpenTrue, id]);

  const handleChatOpen = isPublic
    ? handleNewReview
    : () => {
        if (!isMyProfile) {
          handleOpen({
            title: t("buttonChat"),
            type: "CHAT",
            id,
          });
        } else {
          history.push("/my/chats");
        }
      };

  const isDisabledChatPopOver =
    (!isBusinessType && freeChat) || (isMyProfile && isBusinessType);

  const isDefaultPublicID = /_[^_]*_$/.test(publicId);

  const hostName = getHostName(introVideo);
  const isVimeo = hostName === "vimeo";
  const viemoTransformLink = isVimeo ? convertVimeoUrl(introVideo) : null;
  const playerContainer = useRef(null);
  const player = useRef(null);

  const [isReady, setIsReady] = useState(false);
  const [headerVideoReady, setHeaderVideoReady] = useState(false);
  const [introVideoReady, setIntroVideoReady] = useState(false);

  useEffect(() => {
    if ((headerVideoFile || introVideo) && blobLoading === false) {
      setIsReady(true);
      setHeaderVideoReady(headerVideoFile);
      setIntroVideoReady(!!introVideo);
    }
  }, [headerVideoFile, introVideo, blobLoading]);

  type PlayerOptions = {
    fullscreen: { enabled: boolean; fallback: boolean; iosNative: boolean };
    vimeo: { fullscreen: boolean };
    controls: string[];
    youtube: {
      noCookie: boolean;
      rel: number;
      showinfo: number;
      iv_load_policy: number;
    };
    quality?: {
      default: number;
      options: number[];
      forced: boolean;
      onChange: (e: any) => void;
    };
    i18n?: { qualityLabel: { [key: number]: string } };
  };

  useEffect(() => {
    function updateQuality(newQuality) {
      if (newQuality === 0) {
        window.hls.currentLevel = -1;
      } else {
        window.hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            window.hls.currentLevel = levelIndex;
          }
        });
      }
    }
    if (playerContainer.current && isReady) {
      const defaultOptions: PlayerOptions = {
        fullscreen: { enabled: true, fallback: true, iosNative: true },
        vimeo: { fullscreen: true },
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "settings",
          "fullscreen",
        ],
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
        },
      };

      if (
        !Hls.isSupported() ||
        headerVideoFile?.adaptivePlaylist === false ||
        headerVideoFile?.adaptivePlaylist === null ||
        headerVideoFile?.adaptivePlaylist === undefined
      ) {
        player.current = new Plyr(playerContainer.current, defaultOptions);
      } else {
        const source = `${headerVideoLink}/master_playlist.m3u8`;
        const hls = new Hls({ autoStartLoad: false });
        hls.loadSource(source);
        hls.attachMedia(playerContainer.current);
        window.hls = hls;

        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          const availableQualities = hls.levels.map((l) => l.height);
          availableQualities.unshift(0);

          defaultOptions.quality = {
            default: 0,
            options: availableQualities,
            forced: true,
            onChange: (e) => updateQuality(e),
          };

          defaultOptions.i18n = {
            qualityLabel: {
              0: "Auto",
            },
          };

          hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
            var span = document.querySelector(
              ".plyr__menu__container [data-plyr='quality'][value='0'] span"
            );
            if (hls.autoLevelEnabled) {
              span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`;
            } else {
              span.innerHTML = `AUTO`;
            }
          });

          player.current = new Plyr(playerContainer.current, defaultOptions);
          if (player.current) {
            player.current.on("play", () => {
              hls.startLoad();
            });
          }
        });
      }
      if (player.current) {
        player.current.on("loadedmetadata", () => {});

        player.current.on("enterfullscreen", () => {
          if (/iPhone/i.test(navigator.userAgent)) {
            let videoElement = document.querySelector(
              ".plyr:not(.plyr--fullscreen) video"
            ) as HTMLElement;
            if (videoElement) {
              videoElement.style.display = "block";
              videoElement.style.objectFit = "contain";
              videoElement.style.width = "auto";
              videoElement.style.height = "100%";
              videoElement.style.margin = "0 auto";
            }
          }
        });

        player.current.on("exitfullscreen", () => {
          if (/iPhone/i.test(navigator.userAgent)) {
            let videoElement = document.querySelector(
              ".plyr:not(.plyr--fullscreen) video"
            ) as HTMLElement;
            if (videoElement) {
              videoElement.style.display = "block";
              videoElement.style.objectFit = "contain";
              videoElement.style.width = "auto";
              videoElement.style.height = "208px";
              videoElement.style.margin = "0 auto";
            }
          }
        });
      }
    }

    return () => {
      if (player.current) {
        player.current.destroy();
      }
    };
  }, [isReady]);

  const poster =
    headerVideoFile?.adaptivePlaylist === true
      ? headerVideoLink + "/poster.jpg"
      : null;

  const handleCopy = () => {
    handleClipboard({ text: publicId, toastText: "toastUrlCopied" });
  };

  return (
    <>
      <div className="flex pt-3 flex-col px-5 items-center md:px-5 md:pb-7 md:shadow md:border border-transparent rounded-lg md:rounded-lg text-sm relative bg-white">
        <div
          className={cx(
            `md:h-[140px] md:z-20 md:w-[140px] rounded-2xl md:rounded-full md:-mt-20 relative ${
              isDefault ? `h-[${width}px] w-full` : ""
            }`,
            {
              "h-[100px] w-[100px] rounded-full -mt-16": isModal,
            }
          )}
        >
          {avatar ? (
            <img
              onClick={() => {
                if (isMyProfile) {
                  handleProfileButton();
                  setEditType({ type: "PROFILE_DATA" });
                }
              }}
              src={avatar}
              alt="avatar"
              className={cx(
                "w-full h-full  md:rounded-full md:ring-4 ring-white bg-slate-50 bg-avatar-gradient object-cover",
                {
                  "rounded-full ring-4": isModal,
                  "rounded-[20px]": !isModal,
                  "cursor-pointer": !isModal && isMyProfile,
                }
              )}
            />
          ) : (
            <Image
              className="rounded-full"
              width={100}
              height={100}
              src="/img/avatar-profile.svg"
              alt="avatar"
              layout="fill"
            />
          )}
          {isOnline ? (
            <span
              className={cx(
                "md:block w-3 h-3 md:w-4 md:h-4 ring-4 ring-white rounded-full bg-emerald-400 absolute top-3 md:top-5 right-0.5 md:right-1",
                { block: isModal, hidden: isDefault }
              )}
            />
          ) : null}
          {isModal ? null : isBusinessType && isMobile ? (
            <div className="flex justify-end gap-2.5 -left-3 bottom-6 relative">
              <div className=" block md:hidden">
                <Button
                  onClick={togglePreview}
                  type="follow"
                  className="
             hover:text-gray-300  border border-transparent  px-6 rounded-full md:rounded-md
           text-sm font-medium focus:outline-none
             focus:ring-offset-black focus:ring-gray-400
            flex items-center py-2.5 pl-2.5 pr-0.5  text-white focus:!ring-offset-0 focus:!ring-0 disabled:opacity-100 md:w-[200px] bg-slate-200 shadow-md justify-center md:pl-3 md:pr-3 md:!py-2.5 md:shadow "
                  icon={
                    <EyeIcon className="text-slate-400 w-6 h-6 md:w-5 md:h-5" />
                  }
                >
                  <span />
                </Button>
              </div>
              <div className=" block md:hidden">
                <SingleSessionButton type="mobile" currency={currency} />
              </div>
              <div className=" block md:hidden">
                <ReactLink to={`/my/settings/account`}>
                  <Button
                    type="follow"
                    className="
             hover:text-gray-300  border border-transparent  px-6 rounded-full md:rounded-md
           text-sm font-medium focus:outline-none
             focus:ring-offset-black focus:ring-gray-400
            flex items-center py-2.5 pl-2.5 pr-0.5  text-white focus:!ring-offset-0 focus:!ring-0  pointer-events-none  disabled:opacity-100 md:w-[200px] bg-slate-200 shadow-md justify-center md:pl-3 md:pr-3 md:!py-2.5 md:shadow "
                    icon={
                      <DotsVerticalIcon className="text-slate-400 w-6 h-6 md:w-5 md:h-5" />
                    }
                  >
                    <span />
                  </Button>
                </ReactLink>
              </div>
              <div className=" block md:hidden">
                <Button
                  type="follow"
                  onClick={handleProfileButton}
                  className="
             hover:text-gray-300  border border-transparent  px-6 rounded-full md:rounded-md
           text-sm font-medium focus:outline-none
             focus:ring-offset-black focus:ring-gray-400
            flex items-center py-2.5 pl-2.5 pr-0.5  text-white focus:!ring-offset-0 focus:!ring-0 disabled:opacity-100 md:w-[200px] bg-custom-blue shadow-md justify-center md:pl-3 md:pr-3 md:!py-2.5 md:shadow "
                  icon={
                    <PencilIconSolid className="text-white w-6 h-6 md:w-5 md:h-5" />
                  }
                >
                  <span />
                </Button>
              </div>
              <div className=" block md:hidden">
                <ShareMenu publicId={publicId} />
              </div>
            </div>
          ) : !isFollowed ? (
            <div className="absolute -bottom-6 left-11 block md:hidden">
              <FollowingBtn
                isFollowed={isFollowed}
                handleFollow={handleFollow}
                followLoading={followLoading}
                disabled={disabled}
                isBusinessType={isBusinessType}
              />
            </div>
          ) : (
            <div className="absolute top-1 right-3 block md:hidden">
              <FollowingBtn
                isFollowed={isFollowed}
                handleFollow={handleFollow}
                followLoading={followLoading}
                disabled={disabled}
                isBusinessType={isBusinessType}
              />
            </div>
          )}
        </div>
        {isModal ? null : !isBusinessType ? (
          <div className="block md:hidden ml-auto ">
            <span
              onClick={handleProfileButton}
              className="pl-2 pt-4 block ring-1 webkit-tap-highlight-color ring-white focus:outline-none focus:ring-0 hover:bg-transparent bg-transparent"
            >
              <Icon className="w-7 h-7 stroke-slate-500" />
            </span>
          </div>
        ) : null}
        <div
          className={cx("md:px-0 w-full", {
            "md:px-6": !isModal,
          })}
        >
          <Tippy
            content={
              <div className="inline-block p-1 text-white text-center text-xs font-medium">
                <p className="mb-6">{t("publicIDDescription")}</p>
                <p>{t("publicIDWarning")}</p>
              </div>
            }
            disabled={!isBusinessType || !isDefaultPublicID}
            className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
            placement="bottom"
          >
            <h1
              onClick={() => {
                if (isMyProfile) {
                  handleProfileButton();
                  setEditType({ type: "PROFILE_DATA" });
                }
              }}
              className={cx(
                "w-full text-center text-2xl font-semibold md:font-bold text-gray-900 lg:mt-5  pt-4 md:pt-0 truncate",
                {
                  "cursor-pointer": isMyProfile,
                }
              )}
              title={fullName}
            >
              <span className="w-full truncate relative">
                {fullName}{" "}
                {isDefaultPublicID && isBusinessType ? (
                  <ExclamationCircleIcon className="inline mb-1 w-6 h-6 text-yellow-400 ml-auto shrink-0" />
                ) : null}
                {isOnline ? (
                  <span className="block md:hidden w-2.5 h-2.5 ring-1 ring-emerald-400 rounded-full bg-emerald-400 absolute -top-0.5 -right-5" />
                ) : null}
              </span>
            </h1>
          </Tippy>
          <p className="text-slate-500 text-center mb-4 md:mb-0">
            {occupation || null}
          </p>
          <Tippy
            content={
              <span>
                {city} {formattedTimeZone}
                {" | "}
                {timeZone}
              </span>
            }
            className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
            placement="bottom"
          >
            <p
              className={cx(
                "w-full mt-4 justify-center items-end truncate cursor-pointer",
                {
                  "hidden md:flex": isDefault,
                  flex: !isDefault,
                }
              )}
            >
              <LocationMarkerIcon className="w-5 h-5 shrink-0 mb-px" />
              <span className="ml-0.5 text-gray-900 truncate capitalize">
                {city}
              </span>
            </p>
          </Tippy>
          <div
            className={cx("my-5 flex justify-center items-center", {
              "absolute -top-6 right-3": isFollowed,
              "hidden md:flex": !isModal,
            })}
          >
            <FollowingBtn
              isFollowed={isFollowed}
              handleFollow={handleFollow}
              followLoading={followLoading}
              disabled={disabled}
              isBusinessType={isBusinessType}
              isModal={isModal}
            />
          </div>
          {isModal ? null : (
            <div className="mt-4 md:mt-5 w-full hidden lg:grid grid-cols-2 divide-x divide-gray-100">
              {isPublic ? (
                <Link href="#reviews" passHref>
                  <div className="col-span-1 w-full p-1.5 mx-auto  flex flex-col items-center justify-center cursor-pointer">
                    <p className="text-gray-400">{t("rating")}</p>
                    <div className="mt-1 flex items-center whitespace-nowrap truncate">
                      <StarIcon
                        className={"w-5 h-5 text-custom-yellow inline"}
                      />
                      <span className="ml-1.5 text-sm font-bold">
                        {rating ? rating : "-"}
                      </span>
                    </div>
                  </div>
                </Link>
              ) : (
                <ReactLink to="#reviews">
                  <div className="col-span-1 w-full p-1.5 mx-auto flex flex-col items-center justify-center cursor-pointer">
                    <p className="text-gray-400">{t("rating")}</p>
                    <div className="mt-1 flex items-center whitespace-nowrap truncate">
                      <StarIcon
                        className={"w-5 h-5 text-custom-yellow inline"}
                      />
                      <span className="ml-1.5 text-sm font-bold">
                        {rating ? rating : "-"}
                      </span>
                    </div>
                  </div>
                </ReactLink>
              )}
              <div className="col-span-1 w-full p-1.5  mx-auto flex flex-col items-center justify-center">
                <p className="text-gray-400">{t("followers")}</p>
                <div className="mt-1 flex items-center whitespace-nowrap truncate">
                  <img
                    width={20}
                    height={20}
                    src="/img/icons/followers.svg"
                    alt="followers"
                  />
                  <span className="ml-1.5 text-sm font-bold">
                    {followers ? followers : "-"}
                  </span>
                </div>
              </div>

              {/* hide before blockchain will ready */}
              {/* <div className="col-span-1 w-full py-1.5 pl-3 pr-1 mx-auto flex flex-col items-center justify-center">
              <p className="text-gray-400">WLH</p>
              <div className="mt-1 flex items-center whitespace-nowrap truncate">
                <img
                  width={20}
                  height={20}
                  src="/img/custom-icons/crypto-logo.svg"
                  alt="followers"
                />
                <span className="ml-1.5 text-sm font-bold">-</span>
              </div>
            </div> */}
            </div>
          )}
          {about && (!isMobile || isModal) ? (
            <p
              className={cx(
                "mt-4 md:mt-5 w-full whitespace-pre-line md:max-h-max after:md:hidden overflow-hidden relative",
                {
                  "after:block after:bg-gradient-to-b after:absolute after:inset-0 after:from-transparent after:via-transparent after:to-white max-h-20 after:h-20":
                    isDefault,
                }
              )}
              onClick={onOpenAllInfo}
            >
              {about}
            </p>
          ) : null}
          <div
            className={cx("mt-4 md:mt-5 w-full gap-3 grid grid-cols-12", {
              hidden: isModal || isMobile,
            })}
          >
            <div
              className={cx("col-span-7 md:col-span-12", {
                hidden: !tabs?.appointments,
              })}
            >
              {tabs?.appointments === true ? (
                isPublic ? (
                  <Link href="#appointments" passHref>
                    <Button
                      icon={
                        <span className="icon-[tabler--calendar-clock] text-slate-400 w-4 h-4 md:w-5 md:h-5" />
                      }
                      fluid
                      className="md:text-slate-500 py-2 font-semibold"
                      type={width > 767 ? "secondaryProfile" : "primaryProfile"}
                    >
                      {t("appointment")}
                    </Button>
                  </Link>
                ) : (
                  <ReactLink to="#appointments">
                    <Button
                      icon={
                        <span className="icon-[tabler--calendar-clock] text-slate-400 w-4 h-4 md:w-5 md:h-5 mt-1" />
                      }
                      fluid
                      className="md:text-slate-500 py-2 font-semibold"
                      type={width > 767 ? "secondaryProfile" : "primaryProfile"}
                    >
                      {t("appointment")}
                    </Button>
                  </ReactLink>
                )
              ) : null}
            </div>

            {/* <Tippy
              content={
                <p className="hidden lg:inline-block p-1 text-white text-center text-xs font-medium">
                  {!isBusinessType && !freeChat && !isMyProfile
                    ? t("freeChatWarning")
                    : t("openChatWarning")}
                </p>
              }
              disabled={isDisabledChatPopOver}
              className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
              placement="bottom"
            >
              {!freeChat ? null : (
                <div
                  className={`${
                    tabs?.sessions ? "col-span-5" : "col-span-12"
                  } md:col-span-12`}
                >
                  <Button
                    icon={<ChatIcon className="h-5 w-5 stroke-slate-500" />}
                    type="secondaryProfile"
                    disabled={!isBusinessType && !freeChat && !isMyProfile}
                    onClick={handleChatOpen}
                    fluid
                    className="!text-slate-500 py-2 font-semibold"
                  >
                    {t("buttonOpenChat")}
                  </Button>
                </div>
              )}
            </Tippy> */}
            <div className="col-span-12 md:hidden">
              {isPublic ? (
                <>
                  {tabs?.tutorials === true && (
                    <Link passHref href="#products">
                      <Button
                        icon={<DownloadIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 font-semibold mb-3"
                        type={"danger"}
                      >
                        {t("tutorialsAndCourses")}
                      </Button>
                    </Link>
                  )}

                  {tabs?.communities === true && (
                    <Link passHref href="#communities">
                      <Button
                        icon={<FireIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 mb-3 font-semibold"
                        type={"danger"}
                      >
                        {t("joinCommunities")}
                      </Button>
                    </Link>
                  )}

                  {tabs?.events === true && (
                    <Link passHref href="#events">
                      <Button
                        icon={<TicketIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 mb-3 font-semibold"
                        type={"danger"}
                      >
                        {t("joinEvents")}
                      </Button>
                    </Link>
                  )}

                  {tabs?.tips === true && (
                    <Link passHref href="#tips">
                      <Button
                        icon={<ThumbUpIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 font-semibold"
                        type={"danger"}
                      >
                        {t("leaveATip")}
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {tabs?.tutorials === true && (
                    <ReactLink to="#products">
                      <Button
                        icon={<DownloadIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 font-semibold mb-3"
                        type={"danger"}
                      >
                        {t("tutorialsAndCourses")}
                      </Button>
                    </ReactLink>
                  )}

                  {tabs?.communities === true && (
                    <ReactLink to="#communities">
                      <Button
                        icon={<FireIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 mb-3 font-semibold"
                        type={"danger"}
                      >
                        {t("joinCommunities")}
                      </Button>
                    </ReactLink>
                  )}

                  {tabs?.events === true && (
                    <ReactLink to="#events">
                      <Button
                        icon={<TicketIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 font-semibold mb-3"
                        type={"danger"}
                      >
                        {t("joinEvents")}
                      </Button>
                    </ReactLink>
                  )}

                  {tabs?.tips === true && (
                    <ReactLink to="#tips">
                      <Button
                        icon={<ThumbUpIcon className="h-6 w-6" />}
                        fluid
                        className="md:!text-slate-500 py-2 font-semibold"
                        type={"danger"}
                      >
                        {t("leaveATip")}
                      </Button>
                    </ReactLink>
                  )}
                </>
              )}
            </div>
          </div>

          {socialNetworks?.length && !isMobile ? (
            <div
              className={cx(
                "w-full mt-4 flex-wrap items-center justify-center text-gray-900 gap-4",
                { "flex md:hidden": isDefault, hidden: isModal }
              )}
            >
              {socialNetworks.map((socialNetwork: string) => {
                const domainName = getTopLevelDomain(socialNetwork);

                return domainName === "default" ? (
                  <a
                    href={socialNetwork}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getIconSocialMedia(socialNetwork)}
                  </a>
                ) : (
                  <AnimatedSocialIcon
                    // @ts-ignore
                    brandName={domainName}
                    url={socialNetwork}
                    width="20px"
                    defaultColor="#9CA3AF"
                    animation="grow"
                    animationDuration={0.8}
                    key={socialNetwork}
                    style={{ padding: "0" }}
                  />
                );
              })}
            </div>
          ) : null}

          {email || phone ? (
            <div
              className={cx("w-full border-t border-gray-100 pt-5 mt-5", {
                "hidden block md:block": isDefault,
              })}
            >
              <h2 className="text-base font-semibold">{t("contacts")}</h2>
              <div className="mt-3 flex flex-wrap whitespace-nowrap gap-2">
                {email ? (
                  <a href={`mailto:${email}`} className="truncate rounded-full">
                    <span className="rounded-full white-outline whitespace-nowrap text-slate-900 text-xs bg-slate-100 hover:bg-slate-200 px-3 py-1.5 font-medium font-poppins truncate">
                      {email}
                    </span>
                  </a>
                ) : null}
                {phone ? (
                  <a href={`tel:${phone}`} className="truncate rounded-full">
                    <span className="rounded-full white-outline whitespace-nowrap text-slate-900 text-xs bg-slate-100 hover:bg-slate-200 px-3 py-1.5 font-medium font-poppins truncate">
                      {phone}
                    </span>
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
          {socialNetworks?.length ? (
            <div
              className={cx(
                "w-full border-t border-gray-100 pt-3 mt-5 flex-wrap items-center justify-center text-gray-900 gap-4",
                {
                  "hidden md:flex": isDefault,
                  flex: !isDefault,
                  "border-b pb-4": isModal,
                }
              )}
            >
              {socialNetworks.map((socialNetwork: string) => {
                const domainName = getTopLevelDomain(socialNetwork);

                return domainName === "default" ? (
                  <a
                    href={socialNetwork}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getIconSocialMedia(socialNetwork)}
                  </a>
                ) : (
                  <AnimatedSocialIcon
                    // @ts-ignore
                    brandName={domainName}
                    url={socialNetwork}
                    width="20px"
                    defaultColor="#9CA3AF"
                    animation="grow"
                    animationDuration={0.8}
                    key={socialNetwork}
                    style={{ padding: "0" }}
                  />
                );
              })}
            </div>
          ) : null}
          <div
            className={cx(
              "md:hidden pb-5 mt-5 border-b border-gray-100 w-full",
              {
                hidden: !isDefault || isMobile,
              }
            )}
          >
            <Button
              className="whitespace-nowrap text-center shadow text-slate-900 bg-slate-50 font-semibold"
              onClick={onOpenAllInfo}
              type="none"
              fluid
            >
              <UserCircleIcon className="w-6 h-6 mr-2" />
              <span className="text-sm">{t("viewProfileInfo")}</span>
            </Button>

            {activeLeaveReview ? (
              <Button
                className="whitespace-nowrap text-center py-2.5 mt-3 font-semibold"
                onClick={handleNewReview}
                fluid
              >
                <span className="text-sm">{t("leaveAReview")}</span>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      {languages?.length || isReady || expertTags?.length ? (
        <div
          className={cx(
            "mt-3 px-5 md:py-7 md:shadow rounded-lg text-sm bg-white",
            {
              "hidden md:block": isDefault,
            }
          )}
        >
          {isReady ? (
            <div className="pb-5 md:border-b md:border-gray-100 md:mb-5">
              <h2 className="text-base font-semibold mb-3">
                {t("videoPresentation")}
              </h2>
              {(headerVideoReady && introVideoReady) || headerVideoReady ? (
                <div className="rounded-lg overflow-hidden">
                  <video
                    ref={playerContainer}
                    controls
                    src={headerVideoLink}
                    poster={poster}
                  />
                </div>
              ) : (
                <div
                  className="plyr__video-embed"
                  ref={playerContainer}
                  data-is-video="true"
                >
                  <iframe
                    src={isVimeo ? viemoTransformLink : introVideo}
                    allowFullScreen
                    allowTransparency
                    allow="autoplay"
                  />
                </div>
              )}
            </div>
          ) : null}

          {expertTags?.length ? (
            <div
              className={cx(
                "pb-2 md:pb-5 bg-white pt-5 border-t border-gray-100 md:pt-0 md:border-t-0",
                {
                  "border-b border-gray-100": isDefault,
                }
              )}
            >
              <h2 className="text-base font-semibold mb-3">{t("tags")}</h2>
              <div className="flex flex-wrap gap-2">
                {expertTags?.map((t: string) => (
                  <span
                    key={t}
                    className="rounded-full white-outline whitespace-nowrap text-slate-900 font-poppins font-medium text-xs bg-slate-100 px-3 py-1.5 truncate"
                  >
                    {t}
                  </span>
                ))}
              </div>
            </div>
          ) : null}

          {languages?.length ? (
            <div
              className={cx(
                "pb-2 md:pb-5 bg-white pt-5 border-t border-gray-100 md:border-t-0",
                {
                  "border-b border-gray-100": isDefault,
                }
              )}
            >
              <h2 className="text-base font-semibold mb-3">{t("speaks")}</h2>
              <div className="flex flex-wrap gap-2">
                {languages?.map((lang: string) => {
                  const languageLabel = getLanguageLabel(lang);

                  return (
                    <span
                      key={languageLabel}
                      className="rounded-full white-outline whitespace-nowrap text-slate-900 font-poppins font-medium text-xs bg-slate-100 px-3 py-1.5 truncate"
                    >
                      {languageLabel}
                    </span>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="mt-3 px-5 pt-6 pb-3 shadow rounded-lg text-sm hidden md:block bg-white">
        <h3 className="font-semibold text-base mb-3">{t("topReview")}</h3>
        {loading ? (
          <div className="mt-10  mb-9 text-center">
            <Loader fullScreen={false} />
          </div>
        ) : lastReview ? (
          <>
            <ReviewItem
              raiting={convertRating(lastReview.rating)}
              text={lastReview.text}
              clientName={getFullName(
                lastReview.authorFirstName,
                lastReview.authorLastName,
                lastReview.authorLocalpart
              )}
              reviewDate={formatDate(lastReview.createdAt)}
              clientImg={lastReview.authorAvatar}
              authorId={lastReview?.authorId}
              isMyProfile={isMyProfile}
            />
            <div className="border-t border-gray-100 my-4" />
            {isPublic ? (
              <Link href="#reviews" passHref>
                <p className="text-center font-bold cursor-pointer">
                  {t("viewAll")}
                </p>
              </Link>
            ) : (
              <ReactLink to="#reviews">
                <p className="text-center font-bold">{t("viewAll")}</p>
              </ReactLink>
            )}
            {activeLeaveReview ? (
              <Button
                className="w-full whitespace-nowrap text-center mt-4"
                onClick={handleNewReview}
              >
                <span className="mx-auto text-base">{t("leaveAReview")}</span>
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <div className="mt-10 mb-9 flex items-center justify-center text-custom-light-slate">
              <InformationCircleIcon className="w-5 h-5" />
              <h2 className="ml-1">{t("reviewsEmpty")}</h2>
            </div>
            {activeLeaveReview ? (
              <Button
                className="w-full whitespace-nowrap text-center mb-4"
                onClick={handleNewReview}
              >
                <span className="mx-auto text-base">{t("leaveAReview")}</span>
              </Button>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default ExpertMainInfo;
