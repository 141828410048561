import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  DownloadIcon,
  LockClosedIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import cx from "classnames";
import {
  BadgeCheckIcon,
  ShoppingCartIcon,
  DownloadIcon as ProductIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { ExpertOfferListItem } from "types/user";
import { getPriceType, cloudfront, makeUrlsClickable } from "utils";
import OfferAttachment from "components/OfferAttachment/OfferAttachment";
import { HourglassIcon } from "components/Icons";
import Icon from "components/Icons/ShareIcon/ShareIcon";
import { useTranslation } from "react-i18next";
import OfferCardMenu from "components/OfferCardMenu/OfferCardMenu";

const ProductExpertCard = ({
  publicId,
  isBusinessType = false,
  loading,
  isMyProfile = false,
  children,
  offerType = "offer",
  timeZonePublic,
  isSoldEnabled,
  offerSlug,
  offers,
  handleCardClick,
  name,
  IconComponent,
  subscribed,
  offerPrice,
  levels,
  daysUntilExpiration,
  expiration,
  sold,
  joined,
  sections,
  cover,
  countryCurrency,
  trial,
  telegramLink,
  handleCopy,
  sharing,
  editLink,
  manageLink,
  isMyProfileis,
  isPublicType,
  isTelegramConnected,
  compareDates,
  eventDate,
  timeZone,
  isCurrentTimeInRange,
  eventDateEnd,
  eventType,
  timezone,
  city,
  isBoughtEvent,
  isFreeOffer,
  timeFormat,
  instoreids,
  isSoldOut,
  price,
  seatsLeft,
  mediaUrl,
  attachemntUrl,
  isBoughtTutorial,
  isExpired,
  isVimeoOrYoutube,
  section,
  adaptivePlaylistCover,
  cardSize,
  offerId,
  isEnabled,
  onDeleteClick,
  updateOfferStatus,
  isPreview,
}: {
  offerSlug?: string;
  offers: ExpertOfferListItem[];
  publicId: string;
  type?: "default" | "public";
  isBusinessType?: boolean;
  loading?: boolean;
  children;
  isMyProfile?: boolean;
  offerType?: string;
  eventType?: string;
  eventDate?: string;
  eventDateEnd?: string;
  participants?: number;
  sold?: number;
  timeZonePublic?: string;
  isSoldEnabled?: boolean;
  handleCardClick;
  name;
  IconComponent;
  subscribed;
  offerPrice;
  levels;
  daysUntilExpiration;
  expiration;
  joined;
  sections;
  cover;
  countryCurrency;
  trial;
  telegramLink;
  handleCopy;
  sharing;
  editLink;
  manageLink;
  isMyProfileis;
  isPublicType;
  isTelegramConnected;
  compareDates;
  timeZone;
  isCurrentTimeInRange;
  timezone;
  city;
  isBoughtEvent;
  isFreeOffer;
  timeFormat;
  instoreids;
  isSoldOut;
  price;
  seatsLeft;
  mediaUrl;
  attachemntUrl;
  isBoughtTutorial;
  isExpired;
  isVimeoOrYoutube;
  section;
  adaptivePlaylistCover;
  cardSize?: string;
  offerId?: string;
  isEnabled?: boolean;
  onDeleteClick?;
  updateOfferStatus?;
  isPreview?: boolean;
}) => {
  const isTutorial = offerType === "product";
  const { t } = useTranslation();
  const isLarge = cardSize === "large";
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <>
      <Fragment key={offerSlug}>
        <section
          data-testid="offer-card"
          className={cx(
            "mt-5 p-4 shadow rounded-t-lg bg-slate-50 cursor-pointer relative",
            {
              "rounded-b-lg mb-5 md:mb-6": !isBusinessType,
            }
          )}
          onClick={handleCardClick}
        >
          <div>
            <div>
              {isLarge && (
                <a
                  href={`${publicId}/${offerType}/${offerSlug}`}
                  target={isBusinessType || isPreview ? `_blank` : `_self`}
                  rel="noopener noreferrer"
                >
                  <div className="flex flex-row justify-between items-center gap-4">
                    <h2
                      data-is-link="true"
                      className={cx(
                        "items-justify text-base font-semibold leading-5 text-slate-900"
                      )}
                    >
                      {name}
                    </h2>
                  </div>
                </a>
              )}
              <div className="flex justify-between gap-3 md:gap-4">
                {!isBoughtTutorial &&
                !isLarge &&
                isMobileDevice &&
                cover !== null ? (
                  <div className="">
                    <OfferAttachment
                      attachemntUrl={cover !== null ? cloudfront + cover : null}
                      mediaUrl={mediaUrl}
                      adaptivePlaylistCover={adaptivePlaylistCover}
                      cover={true}
                      isRounded={true}
                    />
                  </div>
                ) : null}

                <div>
                  {" "}
                  {!isLarge ? (
                    <a
                      href={`${publicId}/${offerType}/${offerSlug}`}
                      target={isBusinessType || isPreview ? `_blank` : `_self`}
                      rel="noopener noreferrer"
                    >
                      <div className="flex flex-row justify-between items-center gap-4">
                        <h2
                          data-is-link="true"
                          className={cx(
                            "items-justify text-base font-semibold leading-5 text-slate-900 whitespace-normal break-word  xl:whitespace-nowrap xl:break-normal xl:max-w-none",
                            {
                              hidden: isLarge,
                              "lg:max-w-[400px] max-w-[121px]":
                                !isBoughtTutorial && cover !== null,
                            }
                          )}
                        >
                          {name}
                        </h2>
                      </div>
                    </a>
                  ) : null}
                  <div
                    className={cx(
                      "py-3 flex flex-wrap gap-x-3 gap-y-1 font-medium text-sm text-slate-500",
                      {
                        "!block space-y-2": !isLarge && !isMobileDevice,
                      }
                    )}
                  >
                    <div className="flex gap-2">
                      {isLarge || (!isLarge && !isMobileDevice) ? (
                        <span className="flex items-center">
                          <ProductIcon className="w-5 h-5 mr-1 text-slate-500" />

                          <span className="first-letter:uppercase">
                            {getPriceType("tutorial")}
                          </span>
                        </span>
                      ) : null}

                      {isBoughtTutorial ? (
                        <span className="flex items-center whitespace-nowrap text-emerald-500">
                          <BadgeCheckIcon className="w-5 h-5 mr-1" />
                          {isFreeOffer ? t("added") : t("purchased")}
                        </span>
                      ) : (
                        <span className="flex items-center whitespace-nowrap">
                          <>
                            <div>
                              <IconComponent className="shrink-0 w-5 h-5 mr-1 bg-slate-500 text-white" />
                            </div>
                          </>
                          {offerPrice}
                        </span>
                      )}
                    </div>

                    <>
                      <div className="flex gap-2">
                        {expiration && !isMobileDevice ? (
                          <span className="flex items-center whitespace-nowrap text-slate-500">
                            <HourglassIcon className="shrink-0 w-5 h-5" />
                            <span className="ml-1">
                              {isBoughtTutorial
                                ? isExpired
                                  ? `0 ${t("daysLeft")}`
                                  : daysUntilExpiration === null &&
                                    !isMyProfileis
                                  ? ` ${t("unlimited")}`
                                  : `${
                                      daysUntilExpiration <= 0
                                        ? 0
                                        : daysUntilExpiration
                                    } ${t("daysLeft")}`
                                : `${
                                    subscribed !== null
                                      ? expiration
                                      : levels[0]?.expiration
                                  } ${t("days")}`}
                            </span>
                          </span>
                        ) : null}
                        {isSoldEnabled ? (
                          <>
                            <span className="flex items-center custom-dark-blue">
                              <ShoppingCartIcon className="w-5 h-5 mr-1 text-custom-dark-blue" />
                              <span className="flex items-center custom-dark-blue">
                                {sold}
                              </span>
                            </span>
                          </>
                        ) : null}
                      </div>
                    </>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {!isBoughtTutorial && !isLarge && !isMobileDevice ? (
                    <div>
                      <OfferAttachment
                        attachemntUrl={
                          cover !== null ? cloudfront + cover : null
                        }
                        mediaUrl={mediaUrl}
                        adaptivePlaylistCover={adaptivePlaylistCover}
                        cover={true}
                        isRounded={true}
                      />
                    </div>
                  ) : null}
                  {!isLarge ? (
                    <ChevronRightIcon
                      className={cx(
                        "w-8 h-8 shrink-0 relative md:sticky text-slate-400"
                      )}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {isLarge && <div className="border-b border-gray-100 mt-3 mb-4" />}
          {isLarge ? (
            isBoughtTutorial ? (
              <>
                <div
                  className="relative text-sm mt-3 break-words overflow-hidden after:block after:bg-gradient-to-b
              after:absolute after:inset-0 after:from-transparent after:via-transparent after:to-white after:h-44 max-h-40 mb-3"
                >
                  {isExpired ? t("tutorialExpired") : null}
                </div>
                {isExpired ? null : (
                  <>
                    {isVimeoOrYoutube ? (
                      <OfferAttachment
                        mediaUrl={section?.link}
                        isBlurred={isExpired}
                      />
                    ) : (
                      <>
                        <div className="whitespace-pre-wrap pb-4">
                          {makeUrlsClickable(section?.details)}
                        </div>
                        <a
                          href={section?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-custom-dark-blue whitespace-pre-line text-sm font-medium"
                        >
                          {section?.link}
                        </a>
                      </>
                    )}
                    <div className="my-4">
                      <OfferAttachment
                        attachemntUrl={
                          sections?.attachments?.[0]?.link === undefined &&
                          sections[0]?.attachments?.[0]?.inStoreId
                            ? cloudfront +
                              sections[0]?.attachments?.[0]?.inStoreId
                            : sections?.attachments?.[0]?.link
                        }
                        fileName={
                          sections?.attachments?.[0]?.fileName === undefined
                            ? sections?.attachments?.[0]?.inStoreId
                                .split("_")
                                .slice(1)
                                .join("_")
                            : sections?.attachments?.[0]?.fileName
                        }
                        isBlurred={isExpired}
                        adaptivePlaylist={
                          section?.attachments[0]?.adaptivePlaylist
                        }
                        section={true}
                        isBought={isBoughtTutorial}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <OfferAttachment
                  attachemntUrl={cover !== null ? cloudfront + cover : null}
                  mediaUrl={mediaUrl}
                  adaptivePlaylistCover={adaptivePlaylistCover}
                  cover={true}
                />
              </>
            )
          ) : null}

          {(cover ||
            (sections && sections[0]?.attachments?.[0]?.link) ||
            attachemntUrl ||
            mediaUrl) &&
          isLarge ? (
            <div className="border-b border-gray-100 mt-6 mb-6" />
          ) : null}

          {isLarge ? (
            isPublicType ? (
              <>
                <div className="max-w-[270px] md:max-w-[300px] mx-auto">
                  <div
                    className={cx(
                      "justify-center items-center gap-2 w-full  border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400",
                      {
                        "bg-landing-red text-white hover:bg-hover-landing-red":
                          isTutorial,
                      }
                    )}
                  >
                    {isBoughtTutorial ? (
                      <span>{t("view")}</span>
                    ) : (
                      <>
                        <DownloadIcon className="w-6 h-6 text-slate-50" />

                        <span className="truncate">
                          {isFreeOffer
                            ? t("addForFree")
                            : `${t("buyFor")} ${
                                countryCurrency?.symbol
                              }${price}`}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Tippy
                content={
                  <p className="inline-block p-1 text-white text-center text-xs font-medium">
                    {t("bookingTutorialWarning")}
                  </p>
                }
                disabled={!isBusinessType}
                className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
                placement="bottom"
              >
                {isTutorial && isExpired ? (
                  <div className="max-w-fit min-w-[260px] md:min-w-[300px] mx-auto flex-col justify-center">
                    <div className="justify-center items-center bg-landing-red hover:bg-hover-landing-red gap-2 w-full text-white border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
                      <DownloadIcon className="w-6 h-6 text-slate-50" />

                      <span className="truncate">{t("buyAgain")}</span>
                    </div>
                  </div>
                ) : (
                  <div className="max-w-fit min-w-[260px] md:min-w-[300px] mx-auto flex-col justify-center">
                    <div
                      className={cx(
                        "justify-center items-center gap-2 w-full text-white border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400",
                        {
                          "pointer-events-none": isBusinessType,
                          "bg-slate-900 hover:bg-slate-800":
                            isBoughtTutorial && isTutorial,
                          "bg-landing-red hover:bg-hover-landing-red":
                            !isBoughtTutorial && isTutorial,
                        }
                      )}
                    >
                      {isTutorial ? (
                        isBoughtTutorial ? (
                          <span>{t("view")}</span>
                        ) : isBoughtTutorial && isExpired ? null : (
                          <>
                            <DownloadIcon className="w-6 h-6 text-slate-50" />

                            <span className="truncate">
                              {isFreeOffer
                                ? t("addForFree")
                                : `${t("buyFor")} ${
                                    countryCurrency?.symbol
                                  }${price}`}
                            </span>
                          </>
                        )
                      ) : null}
                    </div>
                  </div>
                )}
              </Tippy>
            )
          ) : null}

          {sharing === "link" ? (
            <LockClosedIcon className="w-5 h-5 absolute top-3.5 right-3.5 text-slate-500" />
          ) : null}
        </section>
        {isBusinessType ? (
          <div>
            <div className="relative mb-5 gap-2 md:gap-6 md:mb-6 p-4 border-t border-slate-200 bg-slate-100 flex items-center justify-center shadow rounded-b-lg cursor-pointer">
              <Link className="flex items-center" to={editLink}>
                <PencilIcon className="w-5 h-5 mr-1 text-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("buttonEdit")}
                </span>
              </Link>
              <div
                onClick={() => handleCopy(offerSlug)}
                className="flex items-center clipboard"
              >
                <Icon className="w-5 h-5 mr-1 stroke-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("share")}
                </span>
              </div>
              <div className="absolute right-14 z-50">
                <OfferCardMenu
                  offerId={offerId}
                  type="product"
                  offerSlug={offerSlug}
                  publicId={publicId}
                  enabled={isEnabled}
                  updateOfferStatus={updateOfferStatus}
                  onDeleteClick={onDeleteClick}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    </>
  );
};

export default ProductExpertCard;
