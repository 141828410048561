import { useState, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { translate } from "utils";
import { Link } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";

const CreateMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  return (
    <>
      <button
        onClick={openMenu}
        className="border-2 w-[24px] h-[24px] border-emerald-400 bg-emerald-400 rounded-lg font-extrabold flex justify-center items-center"
      >
        <span className="font-extrabold pb-[2px] text-center text-lg text-black">
          +
        </span>
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto z-10"
          static
          open={isOpen}
          onClose={closeMenu}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-black/75 backdrop-filter backdrop-blur-sm"
                aria-hidden="true"
              />
            </Transition.Child>
            <button
              onClick={closeMenu}
              className="absolute top-8 right-8 p-2.5 bg-black/50 border border-white/[0.15] rounded-full cursor-pointer z-20"
            >
              <XIcon className="w-6 h-6 text-white" />
            </button>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-middle bg-white rounded-3xl p-8 shadow-xl max-w-lg w-full text-left transform transition-all">
                <div className="flex justify-center items-center mb-6 border-b border-slate-200 pb-4">
                  <h2 className="text-xl font-bold text-gray-800 ">
                    {translate("createMenu")}
                  </h2>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <Link
                    to="/my/settings/offers/createOffer#communities"
                    className="bg-gray-900 text-white rounded-3xl w-36 h-32 flex items-center justify-center hover:bg-gray-800 text-base text-center"
                  >
                    {translate("community")}
                  </Link>
                  <Link
                    to="/my/settings/offers/createOffer#events"
                    className="bg-gray-900 text-white rounded-3xl w-36 h-32 flex items-center justify-center hover:bg-gray-800 text-base text-center"
                  >
                    {translate("event")}
                  </Link>
                  <Link
                    to="/my/settings/offers/createOffer#products"
                    className="bg-gray-900 text-white rounded-3xl w-36 h-32 flex items-center justify-center hover:bg-gray-800 text-base text-center"
                  >
                    {translate("digitalProduct")}
                  </Link>
                  <Link
                    to="/my/settings/offers/createOffer#appointmets"
                    className="bg-gray-900 text-white rounded-3xl w-36 h-32 flex items-center justify-center hover:bg-gray-800 text-base text-center"
                  >
                    {translate("appointment")}
                  </Link>
                  <Link
                    to="/my/settings/offers/createOffer#tips"
                    className="bg-gray-900 text-white rounded-3xl w-36 h-32 flex items-center justify-center hover:bg-gray-800 text-base text-center"
                  >
                    {translate("tip")}
                  </Link>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CreateMenu;
