import Loader from "components/Loader/Loader";
import Modal from "components/Modal/Modal";
import { QRCode } from "components/QrCode/QrCode";
import QrCodeModal from "components/QrCodeModal/QrCodeModal";
import { useRef, useState } from "react";

const customQrCodeProps = {
  eyeRadius: [
    {
      outer: [0, 40, 0, 40],
      inner: [0, 0, 0, 0],
    },
    {
      outer: [40, 0, 40, 0],
      inner: [0, 0, 0, 0],
    },
    {
      outer: [40, 0, 40, 0],
      inner: [0, 0, 0, 0],
    },
  ],

  logoImage: "/img/qr-logo.png",
  logoWidth: 80,
  size: 280,
};

const OfferQr = ({
  link,
  name,
  authorizeScanner,
}: {
  link: string;
  name?: string;
  authorizeScanner?: boolean;
}) => {
  const qrCodeRef = useRef<HTMLImageElement>(null);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>();

  return (
    <div>
      <Modal
        isOpen={isQrModalOpen}
        onClose={() => setIsQrModalOpen(false)}
        title={name}
      >
        <QrCodeModal link={link} type="offer" />
      </Modal>
      {imgSrc ? (
        authorizeScanner ? (
          <div className=" w-56 m-auto">
            <img src={imgSrc} ref={qrCodeRef} alt="qr" data-is-qr={true} />
          </div>
        ) : (
          <div className="w-20 m-auto" onClick={() => setIsQrModalOpen(true)}>
            <img src={imgSrc} ref={qrCodeRef} alt="qr" data-is-qr={true} />
          </div>
        )
      ) : (
        <Loader fullScreen={false} />
      )}
      <div className="absolute opacity-0 inset-x-0 -z-50">
        <QRCode
          value={`${window.location.origin}/${link}`}
          {...customQrCodeProps}
          setImageSrc={setImgSrc}
        />
      </div>
    </div>
  );
};
export default OfferQr;
