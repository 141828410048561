const Icon = ({
  width = "100%",
  height = "100%",
  className = "",
  fill = "#94a3b8",
}: {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_28520_25532)">
        <path
          d="M13.0002 4.80469V8.80469C6.42519 9.83269 3.98019 15.5927 3.00019 20.8047C2.96319 21.0107 8.38419 14.8427 13.0002 14.8047V18.8047L21.0002 11.8047L13.0002 4.80469Z"
          stroke="stroke-cyan-500"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_28520_25532">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(0 0.804688)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
