import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  GiftIcon,
  LockClosedIcon,
  ThumbUpIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import cx from "classnames";
import {
  ShoppingCartIcon,
  ThumbUpIcon as TipIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { ExpertOfferListItem } from "types/user";
import { getPriceType, cloudfront } from "utils";
import OfferAttachment from "components/OfferAttachment/OfferAttachment";
import OfferQr from "./components/OfferQr";
import Icon from "components/Icons/ShareIcon/ShareIcon";
import { useTranslation } from "react-i18next";
import OfferCardMenu from "components/OfferCardMenu/OfferCardMenu";

const TipExpertCard = ({
  publicId,
  isBusinessType = false,
  loading,
  isMyProfile = false,
  children,
  offerType = "offer",
  timeZonePublic,
  isSoldEnabled,
  offerSlug,
  offers,
  handleCardClick,
  name,
  IconComponent,
  subscribed,
  offerPrice,
  levels,
  daysUntilExpiration,
  expiration,
  sold,
  joined,
  sections,
  cover,
  countryCurrency,
  trial,
  telegramLink,
  handleCopy,
  sharing,
  editLink,
  manageLink,
  isMyProfileis,
  isPublicType,
  isTelegramConnected,
  compareDates,
  eventDate,
  timeZone,
  isCurrentTimeInRange,
  eventDateEnd,
  eventType,
  timezone,
  city,
  isFreeOffer,
  timeFormat,
  instoreids,
  isSoldOut,
  price,
  seatsLeft,
  mediaUrl,
  attachemntUrl,
  isExpired,
  isVimeoOrYoutube,
  section,
  priceType,
  duration,
  tipLink,
  bonus,
  adaptivePlaylistCover,
  cardSize,
  offerId,
  isEnabled,
  onDeleteClick,
  updateOfferStatus,
  isPreview,
}: {
  offerSlug?: string;
  offers: ExpertOfferListItem[];
  publicId: string;
  type?: "default" | "public";
  isBusinessType?: boolean;
  loading?: boolean;
  children;
  isMyProfile?: boolean;
  offerType?: string;
  eventType?: string;
  eventDate?: string;
  eventDateEnd?: string;
  participants?: number;
  sold?: number;
  timeZonePublic?: string;
  isSoldEnabled?: boolean;
  handleCardClick;
  name;
  IconComponent;
  subscribed;
  offerPrice;
  levels;
  daysUntilExpiration;
  expiration;
  joined;
  sections;
  cover;
  countryCurrency;
  trial;
  telegramLink;
  handleCopy;
  sharing;
  editLink;
  manageLink;
  isMyProfileis;
  isPublicType;
  isTelegramConnected;
  compareDates;
  timeZone;
  isCurrentTimeInRange;
  timezone;
  city;
  isFreeOffer;
  timeFormat;
  instoreids;
  isSoldOut;
  price;
  seatsLeft;
  mediaUrl;
  attachemntUrl;
  isExpired;
  isVimeoOrYoutube;
  section;
  priceType;
  duration;
  tipLink;
  bonus;
  adaptivePlaylistCover;
  cardSize?: string;
  offerId?: string;
  isEnabled?: boolean;
  onDeleteClick?;
  updateOfferStatus?;
  isPreview?: boolean;
}) => {
  const isTip = offerType === "tip";
  const { t } = useTranslation();
  const isLarge = cardSize === "large";
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      <Fragment key={offerSlug}>
        <section
          data-testid="offer-card"
          className={cx(
            "mt-5 p-4 shadow rounded-t-lg bg-slate-50 cursor-pointer relative",
            {
              "rounded-b-lg mb-5 md:mb-6": !isBusinessType,
            }
          )}
          onClick={handleCardClick}
        >
          <div
            className={cx({
              "flex justify-between gap-3": isBusinessType,
              hidden: !isLarge,
            })}
          >
            <div>
              <a
                href={`${publicId}/${offerType}/${offerSlug}`}
                target={isBusinessType || isPreview ? `_blank` : `_self`}
                rel="noopener noreferrer"
              >
                <div className="flex flex-row justify-between items-center gap-4">
                  <h2
                    data-is-link="true"
                    className={cx(
                      "items-justify text-base font-semibold leading-5 text-slate-900"
                    )}
                  >
                    {name}
                  </h2>
                </div>
              </a>

              <div className="py-3 flex flex-wrap gap-x-3 gap-y-1 font-medium text-sm text-slate-500">
                <span className="flex items-center">
                  <TipIcon className="w-5 h-5 mr-1 text-slate-500" />
                  <span className="first-letter:uppercase">
                    {getPriceType(priceType)}
                  </span>
                </span>

                {isSoldEnabled ? (
                  <>
                    <span className="flex items-center custom-dark-blue">
                      <ShoppingCartIcon className="w-5 h-5 mr-1 text-custom-dark-blue" />
                      <span className="flex items-center custom-dark-blue">
                        {sold}
                      </span>
                    </span>
                  </>
                ) : null}
              </div>
            </div>
            {isBusinessType ? <OfferQr name={name} link={tipLink} /> : null}
          </div>
          {isLarge && <div className="border-b border-gray-100 mt-3 mb-4" />}
          <div className="flex justify-between gap-3 md:gap-4">
            {!isLarge && isMobileDevice && cover !== null ? (
              <div className="">
                <OfferAttachment
                  attachemntUrl={cover !== null ? cloudfront + cover : null}
                  mediaUrl={mediaUrl}
                  adaptivePlaylistCover={adaptivePlaylistCover}
                  cover={true}
                  isRounded={true}
                />
              </div>
            ) : null}

            <div>
              {" "}
              {!isLarge ? (
                <a
                  href={`${publicId}/${offerType}/${offerSlug}`}
                  target={isBusinessType || isPreview ? `_blank` : `_self`}
                  rel="noopener noreferrer"
                >
                  <div className="flex flex-row justify-between items-center gap-4">
                    <h2
                      data-is-link="true"
                      className={cx(
                        "items-justify text-base font-semibold leading-5 text-slate-900 whitespace-normal break-word xl:whitespace-nowrap xl:break-normal xl:max-w-none",
                        {
                          hidden: isLarge,
                          "lg:max-w-[400px] max-w-[121px]": cover !== null,
                        }
                      )}
                    >
                      {name}
                    </h2>
                  </div>
                </a>
              ) : null}
              <div
                className={cx(
                  "py-3 flex flex-wrap gap-x-3 gap-y-1 font-medium text-sm text-slate-500",
                  {
                    "!block space-y-2": !isLarge && !isMobileDevice,
                  }
                )}
              >
                <div className="flex gap-2">
                  {!isLarge && !isMobileDevice ? (
                    <span className="flex items-center">
                      <TipIcon className="w-5 h-5 mr-1 text-slate-500" />
                      <span className="first-letter:uppercase">
                        {getPriceType("tip")}
                      </span>
                    </span>
                  ) : null}
                </div>

                <></>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {!isLarge && !isMobileDevice ? (
                <div>
                  <OfferAttachment
                    attachemntUrl={cover !== null ? cloudfront + cover : null}
                    mediaUrl={mediaUrl}
                    adaptivePlaylistCover={adaptivePlaylistCover}
                    cover={true}
                    isRounded={true}
                  />
                </div>
              ) : null}
              {!isLarge ? (
                <ChevronRightIcon
                  className={cx(
                    "w-8 h-8 shrink-0 relative md:sticky text-slate-400"
                  )}
                />
              ) : null}
            </div>
          </div>
          {isLarge && (
            <OfferAttachment
              attachemntUrl={cover !== null ? cloudfront + cover : null}
              mediaUrl={mediaUrl}
              adaptivePlaylistCover={adaptivePlaylistCover}
              cover={true}
            />
          )}

          {(cover ||
            (sections && sections[0]?.attachments?.[0]?.link) ||
            attachemntUrl ||
            mediaUrl) &&
          isLarge ? (
            <div className="border-b border-gray-100 mt-6 mb-6" />
          ) : null}

          {isLarge ? (
            isPublicType ? (
              <>
                <div className="max-w-[270px] md:max-w-[300px] mx-auto">
                  <div
                    className={cx(
                      "justify-center items-center gap-2 w-full  border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400",
                      {
                        "bg-slate-900 hover:bg-slate-800 text-white": !isTip,
                        "bg-landing-red text-white hover:bg-hover-landing-red":
                          isTip,
                      }
                    )}
                  >
                    <>
                      <ThumbUpIcon className="w-6 h-6" />
                      <span className="truncate">{t("leaveATip")}</span>
                    </>
                  </div>
                </div>
              </>
            ) : (
              <Tippy
                content={
                  <p className="inline-block p-1 text-white text-center text-xs font-medium">
                    {t("bookingTipWarning")}
                  </p>
                }
                disabled={!isBusinessType}
                className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
                placement="bottom"
              >
                <div className="max-w-fit min-w-[260px] md:min-w-[300px] mx-auto flex-col justify-center">
                  <div
                    className={cx(
                      "justify-center items-center gap-2 w-full bg-landing-red hover:bg-hover-landing-red text-white border border-transparent rounded-lg shadow-sm py-2 px-6 inline-flex font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400",
                      {
                        "pointer-events-none": isBusinessType,
                      }
                    )}
                  >
                    <>
                      <ThumbUpIcon className="w-6 h-6" />
                      <span className="truncate">{t("leaveATip")}</span>
                    </>
                  </div>
                </div>
              </Tippy>
            )
          ) : null}

          {bonus && isLarge ? (
            <p className="flex items-start justify-center gap-1 mt-3">
              <GiftIcon className="w-5 h-5 text-slate-900" />
              <span className="text-gray-900">{t("includesAReward")}</span>
            </p>
          ) : null}
          {sharing === "link" ? (
            <LockClosedIcon className="w-5 h-5 absolute top-3.5 right-3.5 text-slate-500" />
          ) : null}
        </section>
        {isBusinessType ? (
          <div>
            <div className="relative mb-5 gap-2 md:gap-6 md:mb-6 p-4 border-t border-slate-200 bg-slate-100 flex items-center justify-center shadow rounded-b-lg cursor-pointer">
              <Link className="flex items-center" to={editLink}>
                <PencilIcon className="w-5 h-5 mr-1 text-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("buttonEdit")}
                </span>
              </Link>
              <div
                onClick={() => handleCopy(offerSlug)}
                className="flex items-center clipboard"
              >
                <Icon className="w-5 h-5 mr-1 stroke-slate-400" />
                <span className="text-slate-400 text-sm font-medium">
                  {t("share")}
                </span>
              </div>
              <div className="absolute right-14 z-50">
                <OfferCardMenu
                  offerId={offerId}
                  type="tip"
                  offerSlug={offerSlug}
                  publicId={publicId}
                  enabled={isEnabled}
                  updateOfferStatus={updateOfferStatus}
                  onDeleteClick={onDeleteClick}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    </>
  );
};

export default TipExpertCard;
