import { useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import {
  QrcodeIcon,
  ChevronRightIcon,
  DownloadIcon,
  ThumbUpIcon,
  FireIcon,
  TicketIcon,
  StarIcon,
  CollectionIcon,
  DuplicateIcon,
  XIcon,
  ChevronLeftIcon,
} from "@heroicons/react/outline";
import QrCodeModal from "components/QrCodeModal/QrCodeModal";
import { translate, toast } from "utils";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";

const ShareMenu = ({ publicId }) => {
  const [showQR, setShowQR] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowQR(false);
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClipboard = ({
    text,
    toastText,
    isLink = true,
  }: {
    text: string;
    toastText?: string;
    isLink?: boolean;
  }) => {
    try {
      window.navigator.clipboard.writeText(
        isLink ? `${window.location.origin}/${text}` : text
      );
      toast(
        toastText ? translate(toastText) : translate("toastProfileCopied"),
        {
          type: "success",
        }
      );
      setMenuOpen(false);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const handleShare = async (param, text) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: text,
          text,
          url: `${window.location.origin}/${param}`,
        });
      } else {
        handleClipboard({ text: param, toastText: "toastUrlCopied" });
      }
    } catch (error) {
      console.log("Error sharing", error);
    }
  };

  const menuItems = [
    {
      id: 1,
      label: translate("copyProfileLink"),
      icon: (
        <span className="icon-[ci--link] w-6 h-6 text-slate-500 shrink-0" />
      ),
      onClick: () =>
        !isMobile
          ? handleClipboard({ text: publicId, toastText: "toastUrlCopied" })
          : handleShare(publicId, "Waylight profile link"),
    },
    {
      id: 2,
      label: translate("profileQRCode"),
      icon: <QrcodeIcon className="h-6 w-6 text-slate-500" />,
      onClick: () => {
        setShowQR(true);
        setMenuOpen(false);
      },
      hasArrow: true,
    },
    {
      id: 3,
      label: translate("copyCommunitiesLink"),
      icon: <FireIcon className="h-6 w-6 text-slate-500" />,
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#communities`,
              toastText: "toastUrlCopied",
            })
          : handleShare(`${publicId}#communities`, "Waylight Communities link"),
    },
    {
      id: 4,
      label: translate("copyEventsLink"),
      icon: <TicketIcon className="h-6 w-6 text-slate-500" />,
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#events`,
              toastText: "toastUrlCopied",
            })
          : handleShare(`${publicId}#events`, "Waylight Events link"),
    },
    {
      id: 5,
      label: translate("copyProductsLink"),
      icon: <DownloadIcon className="h-6 w-6 text-slate-500" />,
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#products`,
              toastText: "toastUrlCopied",
            })
          : handleShare(`${publicId}#products`, "Waylight Products link"),
    },
    {
      id: 6,
      label: translate("copySessionsLink"),
      icon: (
        <span className="icon-[tabler--calendar-clock] text-slate-500 w-6 h-6" />
      ),
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#appointments`,
              toastText: "toastUrlCopied",
            })
          : handleShare(`${publicId}#appointments`, "Waylight Sessions link"),
    },
    {
      id: 7,
      label: translate("copyTipsLink"),
      icon: <ThumbUpIcon className="h-6 w-6 text-slate-500" />,
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#tips`,
              toastText: "toastUrlCopied",
            })
          : handleShare(`${publicId}#tips`, "Waylight Tips link"),
    },
    {
      id: 8,
      label: translate("copyPortfolioLink"),
      icon: <CollectionIcon className="h-6 w-6 text-slate-500" />,
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#portfolio`,
              toastText: "toastUrlCopied",
            })
          : handleShare(`${publicId}#portfolio`, "Waylight Portfolio link"),
    },
    {
      id: 9,
      label: translate("copyReviewsLink"),
      icon: <StarIcon className="h-6 w-6 text-slate-500" />,
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#reviews`,
              toastText: "toastUrlCopied",
            })
          : handleShare(`${publicId}#reviews`, "Waylight Portfolio link"),
    },
    {
      id: 10,
      label: translate("leaveReviewForMe"),
      icon: (
        <span className="icon-[iconoir--three-stars] h-6 w-6 text-slate-500" />
      ),
      onClick: () =>
        !isMobile
          ? handleClipboard({
              text: `${publicId}#leavereview`,
              toastText: "toastUrlCopied",
            })
          : handleShare(
              `${publicId}#leavereview`,
              `Leave a review for ${publicId}`
            ),
    },
  ];

  const toggleMenu = () => {
    if (showQR) {
      setShowQR(false);
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <Button
            type="follow"
            onClick={() => setMenuOpen(!menuOpen)}
            className="
             hover:text-gray-300  border border-transparent px-6 rounded-full md:rounded-md
           text-sm font-medium focus:outline-none
             focus:ring-offset-black focus:ring-gray-400
            flex items-center py-2.5 pl-2.5 pr-0.5  text-white focus:!ring-offset-0 focus:!ring-0 disabled:opacity-100 md:w-[200px] bg-slate-200 shadow-md justify-center md:pl-3 md:pr-3 md:!py-2.5 md:shadow clipboard"
            icon={
              <span className="icon-[icon-park-solid--share-two] w-6 h-6 text-slate-400 mr-2" />
            }
          />
          <Modal
            widthType="xxs"
            isOpen={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            <>
              <div className="mb-4">
                <h3 className="text-base leading-6 font-semibold text-gray-900 text-center pt-3 pb-2">
                  {translate("shareYourWaylight")}
                </h3>
                <hr className="mt-2 mb-4 border-gray-200" />
              </div>

              <ul className="space-y-6">
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between items-center cursor-pointer"
                    onClick={item.onClick}
                  >
                    <div className="flex items-center space-x-2">
                      {item.icon}
                      <span className="text-sm text-slate-900 font-medium">
                        {item.label}
                      </span>
                    </div>
                    {item.hasArrow ? (
                      <ChevronRightIcon className="h-6 w-6 text-slate-500" />
                    ) : (
                      <DuplicateIcon className="h-6 w-6 text-slate-500" />
                    )}
                  </li>
                ))}
              </ul>
            </>
          </Modal>
          <Modal
            widthType="xxs"
            isOpen={showQR}
            onClose={() => setShowQR(false)}
          >
            <h3 className="text-base flex justify-center pb-4 font-semibold text-gray-900">
              {translate("myProfileQRCode")}
            </h3>
            <ChevronLeftIcon
              className="absolute left-3 top-6 w-6 h-6 cursor-pointer opacity-50"
              onClick={() => {
                setMenuOpen(!menuOpen);
                setShowQR(false);
              }}
            />
            <hr className="mt-2 mb-4 border-gray-200" />
            <QrCodeModal link={publicId} type="share" />
          </Modal>
        </>
      ) : (
        <div className="relative flex space-x-8 overflow-visible" ref={menuRef}>
          <button
            onClick={toggleMenu}
            className="w-auto flex absolute bg-slate-900 md:bg-transparent rounded-full text-white md:text-slate-900 items-center justify-center md:justify-start gap-1 px-5 lg:px-2 py-3 font-semibold"
          >
            <span className="icon-[ooui--share] w-5 h-5 text-white md:text-slate-500" />
            <span className="md:hidden xl:block truncate">
              {translate("share")}
            </span>
          </button>

          <Transition
            show={menuOpen && !showQR}
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-100 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div className="absolute top-10 -left-2 mt-2 w-[350px] bg-white shadow-lg rounded-lg p-4 z-50 border">
              <div className="mb-4">
                <h3 className="text-base font-semibold text-gray-900 text-center pt-4 pb-2">
                  {translate("shareYourWaylight")}
                </h3>
                <hr className="mt-2 mb-4 border-gray-200" />
              </div>
              <ul className="">
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between items-center py-3 px-4 cursor-pointer hover:bg-gray-100 hover:rounded-lg transition-all duration-200"
                    onClick={item.onClick}
                  >
                    <div className="flex items-center space-x-2">
                      {item.icon}
                      <span className="text-sm text-slate-900 font-medium">
                        {item.label}
                      </span>
                    </div>
                    {item.hasArrow ? (
                      <ChevronRightIcon className="h-6 w-6 text-slate-500" />
                    ) : (
                      <DuplicateIcon className="h-6 w-6 text-slate-500" />
                    )}
                  </li>
                ))}
              </ul>

              <XIcon
                className="absolute right-3 top-3 w-6 h-6 cursor-pointer opacity-50"
                onClick={toggleMenu}
              />
            </div>
          </Transition>

          {showQR && (
            <div className="absolute top-10 -left-2 mt-2 w-[350px] bg-white shadow-lg rounded-lg p-4 z-50 border">
              <div className="flex justify-center mb-4 mt-6">
                <h3 className="text-base font-semibold text-gray-900">
                  {translate("myProfileQRCode")}
                </h3>
                <ChevronLeftIcon
                  className="absolute left-3 top-4 w-6 h-6 cursor-pointer opacity-50"
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                    setShowQR(false);
                  }}
                />
                <XIcon
                  className="absolute right-3 top-3 w-6 h-6 cursor-pointer opacity-50"
                  onClick={() => setShowQR(false)}
                />
              </div>
              <hr className="mt-2 mb-4 border-gray-200" />
              <QrCodeModal link={publicId} type="offer" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShareMenu;
