import { useQuery } from "@tanstack/react-query";
import { api } from "utils/api";

export default function useFollowers(id: string) {
  const {
    data: followers,
    isLoading: followersLoading,
    refetch: getFollowers,
  } = useQuery(
    ["followers", id],
    () => api.get(`/experts/v1/experts/favorite-count/${id}`),
    {
      enabled: !!id,
    }
  );

  return {
    followers,
    followersLoading,
    getFollowers,
  };
}
