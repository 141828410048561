import { useRef, useState } from "react";
import {
  DownloadIcon,
  QrcodeIcon,
  DuplicateIcon,
} from "@heroicons/react/outline";
import cx from "classnames";
import { toast, translate } from "utils";
import useBrowser from "hooks/useBrowser";
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import { QRCode } from "components/QrCode/QrCode";

const customQrCodeProps = {
  eyeRadius: [
    {
      outer: [0, 40, 0, 40],
      inner: [0, 0, 0, 0],
    },
    {
      outer: [40, 0, 40, 0],
      inner: [0, 0, 0, 0],
    },
    {
      outer: [40, 0, 40, 0],
      inner: [0, 0, 0, 0],
    },
  ],

  logoImage: "/img/qr-logo.png",
  logoWidth: 140,
  size: 512,
};

const QrCodeModal = ({
  link,
  type,
}: {
  link: string;
  type: "default" | "offer" | "share";
}) => {
  const qrCodeRef = useRef<HTMLImageElement>(null);
  const [isCopying, setIsCopying] = useState(false);
  const { browser, isMobile } = useBrowser();
  const [imgSrc, setImgSrc] = useState<string>();

  const handleCopy = async () => {
    setIsCopying(true);
    fetch(imgSrc)
      .then((res) => res.blob())
      .then((blob) => {
        navigator.clipboard.write([
          // eslint-disable-next-line no-undef
          new ClipboardItem(
            Object.defineProperty({}, blob.type, {
              value: blob,
              enumerable: true,
            })
          ),
        ]);
        toast(translate("toastQrCopied"), { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsCopying(false));
  };

  const isDefault = type === "default";
  const isShareType = type === "share";

  return (
    <div className="m-auto relative text-center">
      <div
        className={cx("divide-y", {
          "mb-5": isMobile,
        })}
      >
        <div className="flex flex-col items-center gap-4 ">
          {isDefault ? (
            <>
              {" "}
              <div className="p-3 rounded-full bg-indigo-100">
                <QrcodeIcon className="w-5 text-blue-800" />
              </div>
              <h2 className="text-lg font-light mb-2">
                {isMobile && browser === "chrome"
                  ? translate("qrSharingMobile")
                  : translate("qrSharing")}
              </h2>
            </>
          ) : null}
          <div className="absolute opacity-0 -z-50">
            <QRCode
              value={`${window.location.origin}/${link}`}
              {...customQrCodeProps}
              setImageSrc={setImgSrc}
            />
          </div>

          {imgSrc ? (
            <div
              className={`m-auto ${isShareType && !isMobile ? "w-28" : "w-60"}`}
            >
              <img src={imgSrc} ref={qrCodeRef} alt="qr" />
            </div>
          ) : (
            <Loader fullScreen={false} />
          )}
          {isMobile && browser === "chrome" ? null : (
            <>
              <div
                className={cx("flex", {
                  "pointer-events-none": !imgSrc,
                })}
              >
                {browser === "chrome" ? (
                  <Button
                    className="my-2 sm:my-6 bg-black mr-2.5 text-white"
                    fluid
                    onClick={handleCopy}
                    icon={<DuplicateIcon className="h-5 w-5" />}
                    type="none"
                    loading={isCopying}
                    disabled={!qrCodeRef}
                  >
                    {translate("copy")}
                  </Button>
                ) : null}
                <a href={imgSrc} download={`${link}-waylight-qrcode.png`}>
                  <Button
                    className="my-2 sm:my-6 bg-black text-white"
                    fluid
                    icon={<DownloadIcon className="h-5 w-5" />}
                    type="none"
                  >
                    {translate("download")}
                  </Button>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QrCodeModal;
