import React, { useState } from "react";
import cx from "classnames";
import { StarIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { cloudfront, getTopLevelDomain, getIconSocialMedia } from "utils";
import Tippy from "@tippyjs/react";
import { AnimatedSocialIcon } from "react-animated-social-icons";
import UserModal from "components/UserModal/UserModal";

interface Props {
  raiting: string;
  text: string;
  clientName: string;
  reviewDate: string;
  clientImg?: string;
  type?: "default" | "list";
  authorId: string;
  authorSocialNetworks?: string[];
  authorBio?: string;
  isMyProfile?: boolean;
  authorCity?: boolean;
}

function ReviewItem({
  raiting,
  text,
  clientName,
  reviewDate,
  clientImg,
  type = "default",
  authorId,
  authorSocialNetworks,
  authorBio,
  isMyProfile,
  authorCity,
}: Props) {
  const isListType = type === "list";
  const avatarUrl = `${cloudfront}${clientImg}`;
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const isMobileDevice =
    typeof window !== "undefined" &&
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const closeModal = () => {
    setIsUserModalOpen(false);
  };
  const tooltipContent = (
    <div className="bg-white shadow-lg rounded-lg p-4 w-64 text-center relative">
      <img
        src={avatarUrl}
        alt="avatar"
        className="rounded-full w-16 h-16 mx-auto mb-3"
      />
      <h3 className="text-lg font-semibold text-gray-800">{clientName}</h3>
      <p className="text-sm text-gray-600 mb-3">
        {authorBio?.length > 80
          ? `${authorBio?.slice(0, 77)}...`
          : authorBio || ""}
      </p>
      <div className="flex justify-center gap-2 items-center">
        {authorSocialNetworks?.map((socialNetwork: string) => {
          const domainName = getTopLevelDomain(socialNetwork);

          return domainName === "default" ? (
            <a href={socialNetwork} target="_blank" rel="noopener noreferrer">
              {getIconSocialMedia(socialNetwork)}
            </a>
          ) : (
            <AnimatedSocialIcon
              // @ts-ignore
              brandName={domainName}
              url={socialNetwork}
              width="20px"
              defaultColor="#9CA3AF"
              animation="grow"
              animationDuration={0.8}
              key={socialNetwork}
              style={{ padding: "0" }}
            />
          );
        })}
      </div>
      <div
        className="absolute left-1/2 transform -translate-x-1/2 w-3 h-3 bg-white rotate-45"
        style={{ bottom: "-0.4rem" }}
      />
    </div>
  );

  return (
    <>
      <UserModal isModalOpen={isUserModalOpen} onModalClose={closeModal}>
        <div className="bg-white rounded-lg mt-24 mb-20 pb-6 md:pb-0">
          <div className="flex pt-3 flex-col px-5 items-center md:px-5 md:pb-7 md:shadow md:border border-transparent rounded-lg md:rounded-lg text-sm relative bg-white">
            <div
              className={cx(
                `md:h-[140px] md:z-20 md:w-[140px] md:rounded-full md:-mt-20 relative  h-[100px] w-[100px] rounded-full -mt-16 `
              )}
            >
              <img
                src={avatarUrl}
                alt="avatar"
                className={cx(
                  "w-full h-full  md:rounded-full md:ring-4 ring-white bg-slate-50 bg-avatar-gradient object-cover rounded-full ring-4"
                )}
              />
            </div>
            <h3 className="text-lg font-bold text-gray-900 my-2">
              {clientName}
            </h3>
            <p
              className={cx(
                "w-full mb-2 justify-center items-end truncate cursor-pointer flex"
              )}
            >
              <LocationMarkerIcon className="w-5 h-5 shrink-0 mb-px" />
              <span className="ml-0.5 text-gray-900 truncate capitalize">
                {authorCity}
              </span>
            </p>
            <p className="text-base font-medium text-gray-600 mb-3">
              {authorBio && authorBio}
            </p>
            <div className="flex justify-center gap-2 items-center">
              {authorSocialNetworks?.map((socialNetwork: string) => {
                const domainName = getTopLevelDomain(socialNetwork);

                return domainName === "default" ? (
                  <a
                    href={socialNetwork}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getIconSocialMedia(socialNetwork)}
                  </a>
                ) : (
                  <AnimatedSocialIcon
                    // @ts-ignore
                    brandName={domainName}
                    url={socialNetwork}
                    width="20px"
                    defaultColor="#9CA3AF"
                    animation="grow"
                    animationDuration={0.8}
                    key={socialNetwork}
                    style={{ padding: "0" }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </UserModal>
      <div className={cx("", { "flex shadow p-3 rounded-lg": isListType })}>
        {isListType &&
          (isMobileDevice ? (
            isMyProfile ? (
              <a
                style={{
                  backgroundImage: `url(${cloudfront + clientImg})`,
                }}
                className="h-10 w-10 mr-3 rounded-full bg-center bg-cover bg-no-repeat shrink-0"
                target="_blank"
                rel="noreferrer"
                href={`/my/clients/${authorId}`}
              />
            ) : (
              <div
                onClick={() => setIsUserModalOpen(true)}
                style={{
                  backgroundImage: `url(${cloudfront + clientImg})`,
                }}
                className="h-10 w-10 mr-3 rounded-full bg-center bg-cover bg-no-repeat shrink-0"
              />
            )
          ) : (
            <Tippy
              content={tooltipContent}
              interactive={true}
              className="bg-white rounded-xl custom-tooltip"
              placement="top"
              arrow={false}
            >
              {isMyProfile ? (
                <a
                  style={{
                    backgroundImage: `url(${cloudfront + clientImg})`,
                  }}
                  className="h-10 w-10 mr-3 rounded-full bg-center bg-cover bg-no-repeat shrink-0"
                  target="_blank"
                  rel="noreferrer"
                  href={`/my/clients/${authorId}`}
                />
              ) : (
                <div
                  onClick={() => setIsUserModalOpen(true)}
                  style={{
                    backgroundImage: `url(${cloudfront + clientImg})`,
                  }}
                  className="h-10 w-10 mr-3 rounded-full bg-center bg-cover bg-no-repeat shrink-0"
                />
              )}
            </Tippy>
          ))}

        <div className="w-full">
          <div className="grid grid-cols-12 gap-1">
            {!isListType &&
              (isMyProfile ? (
                <a
                  style={{
                    backgroundImage: `url(${cloudfront + clientImg})`,
                  }}
                  className="col-span-3 lg:col-span-2 h-10 w-10 rounded-full bg-center bg-cover bg-no-repeat shrink-0"
                  target="_blank"
                  rel="noreferrer"
                  href={`/my/clients/${authorId}`}
                />
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${cloudfront + clientImg})`,
                  }}
                  className="col-span-3 lg:col-span-2 h-10 w-10 rounded-full bg-center bg-cover bg-no-repeat shrink-0"
                />
              ))}
            <div className="col-span-8 lg:col-span-8">
              <p className="text-base font-semibold text-gray-900 truncate">
                {clientName}
              </p>
              <p className="text-xs text-gray-500">{reviewDate}</p>
            </div>
            <div className="col-span-3 lg:col-span-2 ml-auto pt-1 pb-3 flex items-center">
              <StarIcon className="w-6 h-6 mr-1 text-star-yellow inline" />
              <span className="text-sm font-bold text-gray-800">{raiting}</span>
            </div>
          </div>
          <div
            className={cx("text-sm text-gray-700", {
              "mt-4": !isListType,
              "mt-2": isListType,
            })}
          >
            <p className="text-gray-900 break-words"> {text}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewItem;
