import { useQuery } from "@tanstack/react-query";
import { api } from "utils/api";

export default function useReview(id: string) {
  const {
    data: review = {},
    isLoading: reviewLoading,
    refetch: getReview,
  } = useQuery(
    ["review", id],
    () => api.get(`/experts/v1/ratings/expert_rating/${id}`),
    {
      enabled: !!id,
    }
  );

  return {
    review,
    reviewLoading,
    getReview,
  };
}
