import React, { useState, useEffect, Fragment } from "react";
import ImageGallery from "react-image-gallery";
import { Dialog, Transition } from "@headlessui/react";
import "react-image-gallery/styles/css/image-gallery.css";
import PortfolioItem from "./components/PortfolioItem/PortfolioItem";
import PortfolioButton from "./components/PortfolioButton/PortfolioButton";
import { XIcon } from "@heroicons/react/outline";

function ExpertPortfolio({
  files,
  onClose,
  show,
  selectedItem,
  height,
  width,
}: {
  files: any;
  onClose: () => void;
  show: boolean;
  selectedItem?: number;
  height: number;
  width: number;
}) {
  const [currentIndex, setCurrentIndex] = useState(selectedItem);

  useEffect(() => {
    setCurrentIndex(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (width < 768) {
      // @ts-ignore
      document.body.style = `height: ${height}px; overflow: hidden;`;
    }

    return () => {
      // @ts-ignore
      document.body.style = `height: unset; overflow: unset;`;
    };
  }, []);

  const onCloseModal = () => {
    onClose();
    setCurrentIndex(1);
  };

  if (!show) {
    return null;
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 w-full h-screen overflow-hidden"
        open={show}
        onClose={onCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black/75 backdrop-filter backdrop-blur-sm"
            aria-hidden="true"
          />
        </Transition.Child>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2  w-screen md:w-auto px-4 md:px-0">
            <div className="flex justify-between text-white mb-6 mx-6 font-semibold">
              <p className="text-xl w-2/3 truncate">
                {files[currentIndex - 1]?.name?.split(".")[0]}
              </p>
              <p>
                <span className="text-xl">{currentIndex}</span>
                <span>/{files.length}</span>
              </p>
            </div>
            <ImageGallery
              items={files}
              renderLeftNav={(onClick, disabled) => (
                <PortfolioButton
                  onClick={onClick}
                  disabled={disabled}
                  currentIndex={currentIndex}
                  type="left"
                />
              )}
              renderRightNav={(onClick, disabled) => (
                <PortfolioButton
                  onClick={onClick}
                  disabled={disabled}
                  currentIndex={currentIndex}
                  lastIndex={files.length}
                  type="right"
                />
              )}
              showPlayButton={false}
              showFullscreenButton={false}
              infinite={false}
              onSlide={(e) => setCurrentIndex(e + 1)}
              renderItem={PortfolioItem}
              startIndex={selectedItem - 1}
            />
          </div>
        </Transition.Child>
        <div
          className="absolute top-5 right-7 md:top-7 md:right-5 p-1 cursor-pointer"
          onClick={onCloseModal}
        >
          <XIcon className="w-6 h-6 text-white" />
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ExpertPortfolio;
