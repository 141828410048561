import cx from "classnames";
import { checkIsVideo } from "helpers";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import { cloudfront } from "utils";

declare global {
  interface Window {
    hls: any;
  }
}

const ExpertPortfolioContentItem = ({
  file,
  index,
  isListType,
  portfolioImgList,
  setSelectedItem,
  setShowPortfolio,
  currentIndex,
  isPresentationPlaying,
  playVideo,
  isTablet,
}: {
  file;
  index: number;
  isListType: boolean;
  portfolioImgList: any[];
  setSelectedItem: (value: number | ((prevState: number) => number)) => void;
  setShowPortfolio: (
    value: boolean | ((prevState: boolean) => boolean)
  ) => void;
  currentIndex: number;
  isPresentationPlaying: boolean;
  playVideo: boolean;
  isTablet: boolean;
}) => {
  const fileType = file.inStoreId.split(".").slice(-1)[0];
  const isVideo = checkIsVideo(fileType);
  const handleImgClick = () => {
    setSelectedItem(
      portfolioImgList.findIndex(({ original }) =>
        original.includes(file.inStoreId)
      ) + 1
    );
    setShowPortfolio(true);
  };

  const isPlaying =
    !isPresentationPlaying && index === currentIndex ? playVideo : false;

  const playerContainer = useRef(null);
  const player = useRef(null);

  const isIphone = /iPhone/i.test(navigator.userAgent);

  type PlayerOptions = {
    fullscreen: { enabled: boolean; fallback: boolean; iosNative: boolean };
    vimeo: { fullscreen: boolean };
    controls: string[];
    youtube: {
      noCookie: boolean;
      rel: number;
      showinfo: number;
      iv_load_policy: number;
    };
    quality?: {
      default: number;
      options: number[];
      forced: boolean;
      onChange: (e: any) => void;
    };
    i18n?: { qualityLabel: { [key: number]: string } };
  };

  useEffect(() => {
    function updateQuality(newQuality) {
      if (newQuality === 0) {
        window.hls.currentLevel = -1;
      } else {
        window.hls.levels.forEach((level, levelIndex) => {
          if (level.height === newQuality) {
            // console.log("Found quality match with " + newQuality);
            window.hls.currentLevel = levelIndex;
          }
        });
      }
    }
    if (playerContainer.current) {
      const defaultOptions: PlayerOptions = {
        fullscreen: { enabled: true, fallback: true, iosNative: true },
        vimeo: { fullscreen: true },
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          !isIphone && "mute",
          "volume",
          "captions",
          "settings",
          "fullscreen",
        ],
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
        },
      };

      if (
        !Hls.isSupported() ||
        file?.adaptivePlaylist === false ||
        file?.adaptivePlaylist === null ||
        file?.adaptivePlaylist === undefined
      ) {
        player.current = new Plyr(playerContainer.current, defaultOptions);
      } else {
        const source = cloudfront + `${file.inStoreId}/master_playlist.m3u8`;
        const hls = new Hls({ autoStartLoad: false });
        hls.loadSource(source);
        hls.attachMedia(playerContainer.current);
        window.hls = hls;

        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          const availableQualities = hls.levels.map((l) => l.height);
          availableQualities.unshift(0);

          defaultOptions.quality = {
            default: 0,
            options: availableQualities,
            forced: true,
            onChange: (e) => updateQuality(e),
          };

          defaultOptions.i18n = {
            qualityLabel: {
              0: "Auto",
            },
          };

          hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
            var span = document.querySelector(
              ".plyr__menu__container [data-plyr='quality'][value='0'] span"
            );
            if (hls.autoLevelEnabled) {
              span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`;
            } else {
              span.innerHTML = `AUTO`;
            }
          });

          player.current = new Plyr(playerContainer.current, defaultOptions);
          if (player.current) {
            player.current.on("play", () => {
              hls.startLoad();
            });
          }
        });
      }

      if (player.current) {
        player.current.on("play", () => {});

        player.current.on("enterfullscreen", () => {
          if (isIphone) {
            let videoElement = document.querySelector(
              ".plyr:not(.plyr--fullscreen) video"
            ) as HTMLElement;
            if (videoElement) {
              videoElement.style.display = "block";
              videoElement.style.objectFit = "contain";
              videoElement.style.width = "auto";
              videoElement.style.height = "100%";
              videoElement.style.margin = "0 auto";
            }
          }
        });

        player.current.on("exitfullscreen", () => {
          if (isIphone) {
            let videoElement = document.querySelector(
              ".plyr:not(.plyr--fullscreen) video"
            ) as HTMLElement;
            if (videoElement) {
              videoElement.style.display = "block";
              videoElement.style.objectFit = "contain";
              videoElement.style.width = "auto";
              videoElement.style.height = "208px";
              videoElement.style.margin = "0 auto";
            }
          }
        });
      }
    }

    return () => {
      if (player.current) {
        player.current.destroy();
      }
    };
  }, []);

  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    if (file?.adaptivePlaylist === true) {
      let img = new Image();

      img.onload = function (this: HTMLImageElement) {
        if (this.width < this.height) {
          setIsPortrait(true);
        }
      };

      img.src = cloudfront + file.inStoreId + "/poster.jpg";
    }
  }, []);
  const poster =
    file?.adaptivePlaylist === true
      ? cloudfront + file.inStoreId + "/poster.jpg"
      : null;
  return (
    <div
      className={cx("relative overflow-hidden cursor-pointer", {
        "h-auto mb-3": !isListType && !isTablet,
        "col-span-2": isListType || isTablet,
        "react-player-object-fit-contain": isPlaying,
      })}
      key={file.inStoreId}
    >
      {isVideo ? (
        <div
          className={` ${
            isPortrait && file?.adaptivePlaylist === true ? "video-wrapper" : ""
          }`}
          style={{
            backgroundImage:
              !isPortrait && file?.adaptivePlaylist === false
                ? `none`
                : `url("${cloudfront + file.inStoreId}/poster.jpg")`,
            background:
              !isPortrait || file?.adaptivePlaylist === false
                ? `black`
                : `url("${cloudfront + file.inStoreId}/poster.jpg")`,
          }}
        >
          {" "}
          <video
            ref={playerContainer}
            controls
            src={cloudfront + file.inStoreId}
            poster={poster}
          />
        </div>
      ) : (
        <img
          className="w-full object-fit"
          src={cloudfront + file.inStoreId}
          alt={file.filename}
          onClick={handleImgClick}
        />
      )}
    </div>
  );
};

export default ExpertPortfolioContentItem;
