import React, { ReactNode } from "react";
import Image from "next/image";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const EmtyState = ({
  description,
  header,
  className,
  type = "default",
  children = null,
}: {
  description?: string;
  header?: string;
  className?: string;
  type?: "default" | "lg";
  children?: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        "py-20 px-16 flex flex-col items-center text-center",
        className
      )}
    >
      <div className="sm:mb-8">
        <Image src="/img/no-results.svg" width={200} height={200} alt="" />
      </div>
      {header ? (
        <h2
          className={cx("mb-1", {
            "text-custom-light-slate text-lg": type === "default",
            "text-white text-xl font-semibold": type === "lg",
          })}
        >
          {t(header)}
        </h2>
      ) : null}
      {description ? (
        <p className="text-custom-light-slate text-sm">{t(description)}</p>
      ) : null}
      {children}
    </div>
  );
};

export default EmtyState;
