import React from "react";
import { ReactImageGalleryItem } from "react-image-gallery";

interface Props extends ReactImageGalleryItem {
  height: number;
}

const PortfolioItem = (item: Props) => {
  return (
    <div className="mx-6 mb-6">
      <div className="flex items-center justify-center w-full">
        <img
          src={item?.original}
          alt="Portfolio"
          className="object-contain w-full"
          style={{ maxHeight: `${item.height - 140}px` }}
        />
      </div>
    </div>
  );
};

export default PortfolioItem;
