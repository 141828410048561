import { Fragment, useEffect, useState } from "react";
import { Transition, Menu } from "@headlessui/react";
import {
  CalendarIcon,
  DownloadIcon,
  FireIcon,
  VideoCameraIcon,
  ThumbUpIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import { useMutation } from "@tanstack/react-query";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { Link, useHistory } from "react-router-dom";
import { api } from "utils/api";
import {
  translate,
  findCountryByCode,
  getUniqueCurrencyCodes,
  filteredCountriesWithoutUA,
} from "utils";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import cx from "classnames";
import Loader from "components/Loader/Loader";
import { VideoCameraIcon as SessionIcon } from "@heroicons/react/solid";
import ButtonPrimary from "components/Button/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import Field from "components/Field/Field";
import CreateMenu from "components/CreateMenu/CreateMenu";

export const SingleSessionButton = ({
  type = "desktop",
  isExpert,
  currency,
}: {
  type?: "desktop" | "mobile" | "bottomNav" | "menu";
  isExpert?: boolean;
  currency?;
}) => {
  const history = useHistory();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isCreatingQuickSession, setIsCreatingQuickSession] = useState(false);
  const [isDoubleConfirmation, setIsDoubleConfirmation] = useState(false);
  const [visible, setVisible] = useState(false);
  const [
    isQuickSessionPriceDurationModalOpen,
    setQuickSessionPriceDurationModalOpen,
  ] = useState(false);
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const uniqueCurrencyCodes = getUniqueCurrencyCodes(
    filteredCountriesWithoutUA
  );
  const [priceFormik, setPrice] = useState(0);
  const [durationFormik, setDuration] = useState(20);
  const [currencyFormik, setCurrency] = useState(currency);

  const { mutateAsync: checkNearestSession } = useMutation(() =>
    api.get("/session/v1/single-session/has-nearest-session")
  );

  const { mutateAsync: createSession } = useMutation(
    (payload: any) => api.post("/session/v1/single-session/create", payload),
    {
      onSuccess: (data) => {
        closeQuickSessionPriceDurationModal();
        setConfirmLoading(false);
      },
      onError: (error) => {
        closeQuickSessionPriceDurationModal();
      },
    }
  );
  const { mutateAsync: joinSession } = useMutation((id) =>
    api.patch(`/session/v1/session/${id}/join`)
  );
  useEffect(() => {
    if (isDoubleConfirmation) {
      closeQuickSessionPriceDurationModal();
    }
  }, [isDoubleConfirmation]);

  const createSingleSession = async (
    price?: number,
    currency?: string,
    duration?: number
  ) => {
    try {
      const res = await createSession({
        price_type: "Single session",
        currency,
        amount: price,
        duration,
      });
      await joinSession(res.id);
      setIsDoubleConfirmation(false);

      window.location.assign("/session/" + res.id);
    } catch (e) {
      setIsErrorModalOpen(true);
    }
  };

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const handleQuickSessionClick = async () => {
    setQuickSessionPriceDurationModalOpen(true);
  };

  const handleBook = () => {
    hide();
    history.push("/my/following");
  };

  const closeErrorModal = () => setIsErrorModalOpen(false);
  const closeDoubleConfirmationModal = () => {
    setConfirmLoading(false);
    setIsDoubleConfirmation(false);
  };

  const durationOptions = [
    { value: 20, label: `20 ${translate("min")}` },
    { value: 30, label: `30 ${translate("min")}` },
    { value: 40, label: `40 ${translate("min")}` },
    { value: 50, label: `50 ${translate("min")}` },
    { value: 60, label: `1 ${translate("hour")}` },
    { value: 70, label: `1 ${translate("hour")} 10 ${translate("min")}` },
    { value: 80, label: `1 ${translate("hour")} 20 ${translate("min")}` },
    { value: 90, label: `1 ${translate("hour")} 30 ${translate("min")}` },
    { value: 100, label: `1 ${translate("hour")} 40 ${translate("min")}` },
    { value: 110, label: `1 ${translate("hour")} 50 ${translate("min")}` },
    { value: 120, label: `2 ${translate("hours")}` },
  ];

  const closeQuickSessionPriceDurationModal = () => {
    setQuickSessionPriceDurationModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isErrorModalOpen}
        title={`${translate("toastCannotStartSession")}`}
        icon="exclamation"
        showCloseBtn={false}
      >
        <div className="flex justify-center">
          <Button fluid onClick={closeErrorModal}>
            {translate("close")}
          </Button>
        </div>
      </Modal>

      <Modal
        isOpen={isQuickSessionPriceDurationModalOpen}
        showCloseBtn={false}
        title={translate("specifyQuickSessionPriceDuration")}
        onClose={closeQuickSessionPriceDurationModal}
        icon="usd-circle-gray"
      >
        <div className="mb-6" />
        <Formik
          initialValues={{
            price: 0,
            duration: 20,
            currency: currency,
          }}
          validationSchema={Yup.object().shape({
            price: Yup.string()
              .required("Price is required")
              .test("isPrice", function (value) {
                const { currency } = this.parent;
                const offerPrices = {
                  FREE_PRICE: 0,
                  VALIDATION_CHARS: /^[0-9.]+$/,
                };

                if (!currency) {
                  return false;
                }

                const country = findCountryByCode(currency);
                const max_price = country?.offerPrices?.MAX_PRICE;
                const min_price = country?.offerPrices?.MIN_PRICE;
                const errorPrice =
                  country &&
                  translate(country?.priceLimit, {
                    minPrice: max_price,
                    maxPrice: max_price,
                  });

                const isValid =
                  (parseFloat(value) === offerPrices.FREE_PRICE ||
                    (parseFloat(value) >= min_price &&
                      parseFloat(value) <= max_price)) &&
                  offerPrices.VALIDATION_CHARS.test(value);

                if (!isValid) {
                  return this.createError({ message: errorPrice });
                }

                return true;
              })
              .nullable(),
          })}
          onSubmit={async ({ price, currency, duration }) => {
            hide();
            setIsCreatingQuickSession(true);
            setConfirmLoading(true);
            try {
              const hasNearestSession = await checkNearestSession();
              setPrice(price);
              setDuration(duration);
              setCurrency(currency);
              if (hasNearestSession) {
                setIsDoubleConfirmation(true);
              } else {
                await createSingleSession(price, currency, duration);
              }
            } catch (err) {
              console.log(err.message);
            } finally {
              setIsCreatingQuickSession(false);
            }
          }}
        >
          {(formik) => {
            return (
              <form
                data-testid="quick-paid-session"
                onSubmit={formik.handleSubmit}
              >
                <div className="max-w-md">
                  <div className="flex justify-center items-end">
                    <Field
                      type="string"
                      name="price"
                      value={formik.values.price}
                      durationValue={formik.values.duration}
                      fluid
                      onChange={formik.handleChange}
                      setTime={(e) =>
                        formik.setFieldValue(
                          "duration",
                          parseInt(e.target.value)
                        )
                      }
                      selectTime
                      errorMessage={formik.errors.price}
                      dropdown={true}
                      dropValue={formik.getFieldProps("currency")}
                      curencyList={uniqueCurrencyCodes}
                      durationOptions={durationOptions}
                    />
                  </div>

                  <span className="mt-1 text-sm text-gray-500">
                    {`${translate(
                      "youGetPaidSpecifiedPriceForFixedAmountTime"
                    )}`}
                  </span>
                </div>

                <div className="flex justify-center gap-4 mx-auto mt-8">
                  <Button
                    type="secondary"
                    onClick={closeQuickSessionPriceDurationModal}
                    className="w-40 md:w-50"
                  >
                    {translate("buttonCancel")}
                  </Button>

                  <ButtonPrimary
                    submit={true}
                    fluid
                    loading={isConfirmLoading}
                    type="primary"
                    className="w-40 md:w-50 confirm"
                  >
                    {translate("confirm")}
                  </ButtonPrimary>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        isOpen={isDoubleConfirmation}
        title={`${translate("quickSessionWarningTittle")}`}
        description={`${translate("quickSessionWarningDescription")}`}
        icon="questionMark"
        showCloseBtn={false}
      >
        <div className="flex justify-center gap-3">
          <Button
            onClick={closeDoubleConfirmationModal}
            type="secondary"
            disabled={isCreatingQuickSession}
          >
            {translate("buttonNo")}
          </Button>
          <Button
            onClick={() =>
              createSingleSession(priceFormik, currencyFormik, durationFormik)
            }
            loading={isCreatingQuickSession}
          >
            {translate("buttonStartAnyway")}
          </Button>
        </div>
      </Modal>

      {type === "desktop" ? (
        <div className="hidden md:block pl-3 border-l border-white/20">
          <Menu as="div" className="flex relative">
            <Menu.Button>
              <div className="flex items-center gap-2 py-2.5">
                <div className="border-2 w-[20px] h-[20px] border-emerald-400 bg-emerald-400 rounded-lg font-extrabold  flex justify-center items-center">
                  <span className="font-extrabold pb-[2px] text-center text-lg text-black">
                    +
                  </span>
                </div>
                <span className="text-sm font-medium text-slate-900">
                  {translate("startASession")}
                </span>
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute w-80 right-0 mt-8 w-56 origin-top-right divide-y divide-gray-100 rounded-bl-lg rounded-tl-lg rounded-br-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
                <div className="p-2">
                  <Menu.Item>
                    <button
                      onClick={handleQuickSessionClick}
                      className="flex gap-4 text-sm text-left hover:bg-gray-100 p-2 rounded-lg"
                      disabled={isCreatingQuickSession}
                    >
                      <div className="bg-emerald-400 p-2 rounded-lg inline-flex">
                        <VideoCameraIcon className="w-6 h-6 text-white" />
                      </div>
                      <div>
                        <div className="flex gap-2 items-center font-bold mb-1">
                          {translate("quickSession")}
                          <ArrowRightIcon className="w-4 h-4" />
                        </div>
                        <span className="text-slate-500">
                          {translate("startSessionImmediately")}
                        </span>
                      </div>
                    </button>
                  </Menu.Item>
                </div>
                {isExpert ? (
                  <>
                    <div className="p-2">
                      <Menu.Item>
                        <Link
                          to="/my/settings/offers/createOffer#appointments"
                          className="flex gap-4 text-sm text-left hover:bg-gray-100 p-2 rounded-lg"
                        >
                          <div className="bg-slate-900 p-2 rounded-lg inline-flex self-start">
                            <VideoCameraIcon className="w-6 h-6 text-white" />
                          </div>
                          <div>
                            <div className="flex gap-2 items-center font-bold mb-1">
                              {translate("createSessionOffer")}
                              <ArrowRightIcon className="w-4 h-4" />
                            </div>
                            <span className="text-slate-500">
                              {translate("createSessionOfferDescription")}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>
                    </div>
                    <div className="p-2">
                      <Menu.Item>
                        <Link
                          to="/my/settings/offers/createOffer#products"
                          className="flex gap-4 text-sm text-left hover:bg-gray-100 p-2 rounded-lg"
                        >
                          <div className="bg-ruslan-red p-2 rounded-lg inline-flex self-start">
                            <DownloadIcon className="w-6 h-6 text-white" />
                          </div>
                          <div>
                            <div className="flex gap-2 items-center font-bold mb-1">
                              {translate("createTutorial")}
                              <ArrowRightIcon className="w-4 h-4" />
                            </div>
                            <span className="text-slate-500">
                              {translate("createTutorialDescription")}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>
                    </div>
                    <div className="p-2">
                      <Menu.Item>
                        <Link
                          to="/my/settings/offers/createOffer#communities"
                          className="flex gap-4 text-sm text-left hover:bg-gray-100 p-2 rounded-lg"
                        >
                          <div className="bg-ruslan-red p-2 rounded-lg inline-flex self-start">
                            <FireIcon className="w-6 h-6 text-white" />
                          </div>
                          <div>
                            <div className="flex gap-2 items-center font-bold mb-1">
                              {translate("newCommunityOffer")}
                              <ArrowRightIcon className="w-4 h-4" />
                            </div>
                            <span className="text-slate-500">
                              {translate("newCommunityOfferDescription")}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>
                    </div>
                    <div className="p-2">
                      <Menu.Item>
                        <Link
                          to="/my/settings/offers/createOffer#events"
                          className="flex gap-4 text-sm text-left hover:bg-gray-100 p-2 rounded-lg"
                        >
                          <div className="bg-ruslan-red p-2 rounded-lg inline-flex self-start">
                            <TicketIcon className="w-6 h-6 text-white" />
                          </div>
                          <div>
                            <div className="flex gap-2 items-center font-bold mb-1">
                              {translate("newEventOffer")}
                              <ArrowRightIcon className="w-4 h-4" />
                            </div>
                            <span className="text-slate-500">
                              {translate("newEventOfferDescription")}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>
                    </div>
                    <div className="p-2">
                      <Menu.Item>
                        <Link
                          to="/my/settings/offers/createOffer#tips"
                          className="flex gap-4 text-sm text-left hover:bg-gray-100 p-2 rounded-lg"
                        >
                          <div className="bg-ruslan-red p-2 rounded-lg inline-flex self-start">
                            <ThumbUpIcon className="w-6 h-6 text-white" />
                          </div>
                          <div>
                            <div className="flex gap-2 items-center font-bold mb-1">
                              {translate("newTipOffer")}
                              <ArrowRightIcon className="w-4 h-4" />
                            </div>
                            <span className="text-slate-500">
                              {translate("newTipOfferDescription")}
                            </span>
                          </div>
                        </Link>
                      </Menu.Item>
                    </div>
                  </>
                ) : (
                  <div className="p-2">
                    <Menu.Item>
                      <button
                        onClick={handleBook}
                        className="flex gap-4 text-sm text-left hover:bg-gray-100 p-2 rounded-lg"
                      >
                        <div className="bg-black p-2 rounded-lg inline-flex">
                          <CalendarIcon className="w-6 h-6 text-white" />
                        </div>
                        <div>
                          <div className="flex gap-2 items-center font-bold mb-1">
                            {translate("bookSession")}
                            <ArrowRightIcon className="w-4 h-4" />
                          </div>
                          <span className="text-slate-500">
                            {translate("scheduleSession")}
                          </span>
                        </div>
                      </button>
                    </Menu.Item>
                  </div>
                )}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      ) : null}
      {type === "menu" ? (
        <div
          onClick={handleQuickSessionClick}
          className={cx("flex gap-3 items-center px-3 font-semibold", {
            "pointer-events-none": isCreatingQuickSession,
          })}
        >
          <div
            data-testid="quick-paid-session"
            className="flex items-center gap-2 px-5 md:px-0 py-2.5 w-auto bg-slate-900 md:bg-transparent rounded-full font-semibold"
          >
            <SessionIcon className="w-6 h-6 text-white md:text-slate-500" />
            <span className="text-sm text-white md:text-slate-900 md:hidden xl:block truncate">
              {translate("videoCallQuick")}
            </span>
          </div>
        </div>
      ) : null}
      {type === "mobile" ? (
        <Button
          type="follow"
          onClick={handleQuickSessionClick}
          className="
hover:text-gray-300  border border-transparent  px-6 rounded-full md:rounded-md
text-sm font-medium focus:outline-none
focus:ring-offset-black focus:ring-gray-400
flex items-center py-2.5 pl-2.5 pr-0.5  text-white focus:!ring-offset-0 focus:!ring-0 disabled:opacity-100 md:w-[200px] bg-slate-200 shadow-md justify-center md:pl-3 md:pr-3 md:!py-2.5 md:shadow "
          icon={
            <SessionIcon className="text-slate-400 w-6 h-6 md:w-5 md:h-5" />
          }
        >
          <span />
        </Button>
      ) : null}

      {type === "bottomNav" ? (
        <div
          className={cx("px-3 w-12 py-0.5 relative quick-session", {
            "pointer-events-none": isCreatingQuickSession,
          })}
        >
          {isCreatingQuickSession ? (
            <div className="absolute inset-0">
              <Loader fullScreen={false} />
            </div>
          ) : (
            isExpert && <CreateMenu />
          )}
        </div>
      ) : null}
    </>
  );
};
