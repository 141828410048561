import { HeartIcon } from "@heroicons/react/solid";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/solid";
import cx from "classnames";
import Tippy from "@tippyjs/react";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";

const FollowingBtn = ({
  isFollowed,
  handleFollow,
  followLoading = false,
  disabled = false,
  isBusinessType = false,
  isModal = false,
}: {
  isFollowed: boolean;
  handleFollow: (e: any) => void;
  followLoading?: boolean;
  disabled?: boolean;
  isBusinessType?: boolean;
  isModal?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Tippy
      content={
        <p className="inline-block p-1 text-white text-center text-xs font-medium">
          {t("followWarning")}
        </p>
      }
      disabled={!isBusinessType}
      className="bg-black rounded-lg md:rounded-sm tippy-wrapper"
      placement="bottom"
    >
      <div>
        <Button
          onClick={handleFollow}
          type="follow"
          className={cx(
            "flex items-center justify-center pl-2.5 pr-0.5 font-semibold text-white focus:!ring-offset-0 focus:!ring-0",
            {
              "py-2.5 md:py-4": !followLoading,
              "py-3.5 md:py-2": followLoading,
              "pointer-events-none  disabled:opacity-100": isBusinessType,
              "md:w-[200px] bg-landing-red hover:bg-hover-landing-red justify-center md:pl-3 md:pr-3 md:!py-2.5 md:shadow hover:text-white":
                !isFollowed,
              "!w-[200px] justify-center items-center !pl-3 !pr-3 !py-2.5 !shadow rounded-md":
                !isFollowed && isModal,
              "md:pl-0 md:pr-0": isFollowed,
            }
          )}
          loading={followLoading}
          icon={
            isFollowed ? (
              <HeartIcon className="text-landing-red w-6 h-6 md:w-5 md:h-5" />
            ) : (
              <HeartIconOutline
                className={cx("md:w-5 md:h-5", {
                  "w-6 h-6 text-white": !isModal,
                  "w-5 h-5 text-white": isModal,
                })}
              />
            )
          }
          disabled={disabled}
        >
          {isFollowed ? null : (
            <span
              className={cx("-ml-1", {
                "hidden md:block": !isModal,
                block: isModal,
              })}
            >
              {t("follow")}
            </span>
          )}
        </Button>
      </div>
    </Tippy>
  );
};

export default FollowingBtn;
